import Cookies from "js-cookie";
import {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import Loading from "../others/Loading";
import ReactPaginate from "react-paginate";
import {Button, Col, Modal, Row} from "react-bootstrap";
import {Typeahead} from "react-bootstrap-typeahead";
import {NumericFormat} from "react-number-format";

const Person = () => {
    const token = Cookies.get('token');
    const [formData, setFormData] = useState({});
    const [isModifying, setModifying] = useState(false);

    const [pageNumberPerson, setPageNumberPerson] = useState(0);
    const [pageSizePerson, setPageSizePerson] = useState(10);
    const [totalPerson, setTotalPerson] = useState(0);
    const [searchTermPerson, setSearchTermPerson] = useState('');
    const [isLoadingPerson, setLoadingPerson] = useState(true)
    const [person, setPerson] = useState([])
    const [showAddModalPerson, setAddModalPerson] = useState(false);
    const [showAddConfrimModalPerson, setAddConfirmModalPerson] = useState(false);
    const pageCountPerson = Math.ceil(totalPerson / pageSizePerson);
    const [deletePersonId, setDeletePersonId] = useState(null);
    const [deletePersonName, setDeletePersonName] = useState(null);
    const [showDeleteModalPerson, setShowDeleteModalPerson] = useState(false);
    const [showEditModalPerson, setShowEditModalPerson] = useState(false);
    const [showEditConfrimModalPerson, setEditConfirmModalPerson] = useState(false);

    const [regions, setRegions] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [cities, setCities] = useState([]);
    const [barangays, setBarangays] = useState([]);
    const [puroks, setPuroks] = useState([]);

    const [selectedRegion, setSelectedRegion] = useState(null);
    const [selectedProvince, setSelectedProvince] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedBarangay, setSelectedBarangay] = useState(null);
    const [selectedPurok, setSelectedPurok] = useState(null);

    const [isMember, setIsMember] = useState("");

    const [head, setHead] = useState([]);
    const [selectedHead, setSelectedHead] = useState(null);
    const [isLoadingHead, setIsLoadingHead] = useState(false);
    const [pageHead, setPageHead] = useState(1);
    const [totalHead, setTotalHead] = useState(0);
    const [searchHead, setSearchHead] = useState("");

    // Debounced input handler
    const handleInputChangeHead = ((query) => {
        setSearchHead(query);
        setPageHead(1); // Reset to first page
        fetchHead(1, query);
    });

    useEffect(() => {
        fetchHead(1)
    }, [token]);

    const fetchHead = (page = 1, search = "") => {
        setIsLoadingHead(true);
        axios
            .get(`${process.env.REACT_APP_API_URL}/heads-typeahead`, {
                headers: {Authorization: `Bearer ${token}`},
                params: {page, page_size: 20, search},
            })
            .then((response) => {
                if (page === 1) {
                    setHead(response.data.data); // Replace results for the first page
                } else {
                    setHead((prevHead) => {
                        const newResults = response.data.data.filter(
                            (newHead) => !prevHead.some((person) => head.id === newHead.id)
                        );
                        return [...prevHead, ...newResults];
                    });
                }
                setTotalHead(response.data.total_count); // Update the total number of results
            })
            .catch((error) => {
                console.error("Error fetching persons:", error);
            })
            .finally(() => {
                setIsLoadingHead(false);
            });
    };

    const handleHeadChange = (selected) => {
        if (selected.length > 0) {
            setSelectedHead(selected[0]);
        } else {
            setSelectedHead(null);
        }
    };

    const handleIsMemberChange = (e) => {
        setIsMember(e.target.value); // Update state based on dropdown selection
    };


    const handlePageChangePerson = ({selected}) => {
        setPageNumberPerson(selected);
    };

    useEffect(() => {
        fetchPerson();
    }, [token, pageNumberPerson, pageSizePerson, searchTermPerson]);

    useEffect(() => {
        setPageNumberPerson(0); // Reset the page number when search term changes
    }, [searchTermPerson]);

    const fetchPerson = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/persons?page=${pageNumberPerson + 1}&page_size=${pageSizePerson}&search=${searchTermPerson}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setPerson(response.data.data);
                setTotalPerson(response.data.total); // Ensure total is set correctly
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setLoadingPerson(false)
            })
    };

    function confirmAddPerson(event) {
        event.preventDefault()
        setModifying(true)

        if (!selectedRegion || !selectedProvince || !selectedCity || !selectedBarangay || !selectedPurok) {
            toast.error("Please select a region, province, city, barangay and a purok.");
            setModifying(false);
            return;
        }

        if (isMember === "NO") {
            if (!selectedHead) {
                toast.error("Please select a head of family person.");
                setModifying(false);
                return;
            }
        }


        const formData = new FormData(event.target);


        const data = {
            firstname: formData.get("firstname"),
            middlename: formData.get("middlename"),
            lastname: formData.get("lastname"),
            suffix: formData.get("suffix"),
            gender: formData.get("gender"),
            is_resident: formData.get("is_resident"),
            is_head_of_family: formData.get("is_head_of_family"),
            total_beneficiaries: formData.get("total_beneficiaries") || null,
            region: selectedRegion.name,
            province: selectedProvince.name,
            city: selectedCity.name,
            barangay: selectedBarangay.name,
            purok_name: selectedPurok.purok_name,
            purok_id: selectedPurok.id,
            head_name: `${selectedHead?.person?.lastname || ""}, ${selectedHead?.person?.middlename || ''} ${selectedHead?.person?.firstname || ''} ${selectedHead?.person?.suffix || ''} - ${selectedHead?.person?.purok?.purok_name || ''}`.trim(),
            head_id: selectedHead?.id || null,
            relationship: formData.get("relationship"),
        };

        console.log('Form Data:', data);
        setFormData(data);
        setAddConfirmModalPerson(true);
    }

    function handleAddPerson() {
        axios.post(`${process.env.REACT_APP_API_URL}/add-person`, formData, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                fetchPerson()
                fetchHead(1)
                toast.success(response.data.message)
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setModifying(false)
                setAddModalPerson(false)
                setIsMember('')
                setSelectedRegion(null)
                setSelectedProvince(null)
                setSelectedCity(null)
                setSelectedBarangay(null)
                setSelectedPurok(null)
                setSelectedHead(null)
            })
    }

    function confirmDeletePerson(id, person_name) {
        setDeletePersonId(id);
        setDeletePersonName(`${person_name}`);
        setShowDeleteModalPerson(true);
    }

    function handleDeletePerson(id) {
        axios.delete(`${process.env.REACT_APP_API_URL}/delete-person/${id}`, {
            method: 'DELETE', headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                fetchPerson()
                toast.success(response.data.message)
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            });
    }

    useEffect(() => {
        // Fetch regions on component mount
        axios.get("https://psgc.gitlab.io/api/regions").then((response) => {
            setRegions(response.data);
        });
        fetchPuroks()
    }, []);

    const fetchProvinces = (regionCode) => {
        axios
            .get(`https://psgc.gitlab.io/api/regions/${regionCode}/provinces`)
            .then((response) => {
                setProvinces(response.data);
                setCities([]); // Clear cities when region changes
                setBarangays([]); // Clear barangays when region changes
            });
    };

    const fetchCities = (provinceCode) => {
        axios
            .get(
                `https://psgc.gitlab.io/api/provinces/${provinceCode}/cities-municipalities`
            )
            .then((response) => {
                setCities(response.data);
                setBarangays([]); // Clear barangays when province changes
            });
    };

    const fetchBarangays = (cityMunCode) => {
        axios
            .get(
                `https://psgc.gitlab.io/api/cities-municipalities/${cityMunCode}/barangays`
            )
            .then((response) => {
                setBarangays(response.data);
            });
    };

    const fetchPuroks = () => {
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/puroks-typeahead`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            )
            .then((response) => {
                setPuroks(response.data.data);
            });
    };

    const handleRegionChange = (selected) => {
        if (selected.length > 0) {
            const region = selected[0];
            setSelectedRegion(region);
            fetchProvinces(region.code);
        } else {
            setSelectedRegion(null);
            setProvinces([]);
            setCities([]);
            setBarangays([]);
        }
    };

    const handleProvinceChange = (selected) => {
        if (selected.length > 0) {
            const province = selected[0];
            setSelectedProvince(province);
            fetchCities(province.code);
        } else {
            setSelectedProvince(null);
            setCities([]);
            setBarangays([]);
        }
    };

    const handleCityChange = (selected) => {
        if (selected.length > 0) {
            const city = selected[0];
            setSelectedCity(city);
            fetchBarangays(city.code);
        } else {
            setSelectedCity(null);
            setBarangays([]);
        }
    };

    const handleBarangayChange = (selected) => {
        if (selected.length > 0) {
            setSelectedBarangay(selected[0]);
        } else {
            setSelectedBarangay(null);
        }
    };

    const handlePurokChange = (selected) => {
        if (selected.length > 0) {
            setSelectedPurok(selected[0]);
        } else {
            setSelectedPurok(null);
        }
    };

    function confirmEditPerson(event) {
        event.preventDefault()
        setModifying(true)

        const formData = new FormData(event.target);

        const data = {
            id: formData.get('id'),
            firstname: formData.get("firstname"),
            middlename: formData.get("middlename"),
            lastname: formData.get("lastname"),
            suffix: formData.get("suffix"),
            gender: formData.get("gender"),
            is_resident: formData.get("is_resident"),
            is_head_of_family: formData.get("is_head_of_family"),
            total_beneficiaries: formData.get("total_beneficiaries"),
            region: selectedRegion.name,
            province: selectedProvince.name,
            city: selectedCity.name,
            barangay: selectedBarangay.name,
            purok_name: selectedPurok.purok_name,
            purok_id: selectedPurok.id,
            head_name: `${selectedHead?.person?.lastname || ""}, ${selectedHead?.person?.middlename || ''} ${selectedHead?.person?.firstname || ''} ${selectedHead?.person?.suffix || ''} - ${selectedHead?.person?.purok?.purok_name || ''}`.trim(),
            head_id: selectedHead?.id || null,
            relationship: formData.get("relationship"),

        };
        setFormData(data);
        setEditConfirmModalPerson(true);
    }

    function handleEditPerson() {
        axios.put(`${process.env.REACT_APP_API_URL}/update-person`, formData, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                fetchPerson()
                toast.success(response.data.message)
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setModifying(false)
                setShowEditModalPerson(false)
                setIsMember('')
                setSelectedRegion(null)
                setSelectedProvince(null)
                setSelectedCity(null)
                setSelectedBarangay(null)
                setSelectedPurok(null)
                setSelectedHead(null)
            })
    }

    console.log(person)
    if (isLoadingPerson) {
        return (<Loading/>);
    }


    return (
        <>
            <div className="container-fluid">
                <h3 className="text-center text-white bg-dark py-3 rounded mt-3">
                    <i className="fa fa-users me-2"></i>Person Management
                </h3>
                <div className="card shadow border-0">
                    <div className="card-header d-flex justify-content-between align-items-center bg-light">
                        <h5 className="text-primary fw-bold mb-0">
                            <i className="fa fa-list me-2"></i>Person Information
                        </h5>
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => setAddModalPerson(true)}
                        >
                            <i className="fa fa-plus me-2"></i>Add New Person
                        </button>
                    </div>
                    <div className="card-body">
                        {/* Search and Pagination */}
                        <div className="row mb-4">
                            <div className="col-md-9">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search Person"
                                    value={searchTermPerson}
                                    onChange={(e) => setSearchTermPerson(e.target.value)}
                                />
                            </div>
                            <div className="col-md-3">
                                <select
                                    className="form-select"
                                    value={pageSizePerson}
                                    onChange={(e) => {
                                        setPageSizePerson(Number(e.target.value));
                                        setPageNumberPerson(0);
                                    }}
                                >
                                    {[10, 20, 30, 40, 50].map((size) => (
                                        <option key={size} value={size}>
                                            Show {size} per page
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        {/* Table */}
                        <div className="table-responsive">
                            <table className="table table-bordered table-hover align-middle">
                                <thead className="table-primary">
                                <tr>
                                    <th>#</th>
                                    <th>Last Name</th>
                                    <th>Middle Name</th>
                                    <th>First Name</th>
                                    <th>Suffix</th>
                                    <th>Gender</th>
                                    <th>Region</th>
                                    <th>Province</th>
                                    <th>City</th>
                                    <th>Barangay</th>
                                    <th>Purok</th>
                                    <th>Resident</th>
                                    <th>Head of Family</th>
                                    <th>Total Beneficiaries</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {person.length === 0 ? (
                                    <tr>
                                        <td colSpan="15" className="text-center text-muted">
                                            No results found.
                                        </td>
                                    </tr>
                                ) : (
                                    person.map((data, index) => (
                                        <tr key={data.id}>
                                            <td>{index + 1 + pageNumberPerson * pageSizePerson}</td>
                                            <td>{data.lastname}</td>
                                            <td>{data.middlename}</td>
                                            <td>{data.firstname}</td>
                                            <td>{data.suffix}</td>
                                            <td>{data.gender}</td>
                                            <td>{data.region}</td>
                                            <td>{data.province}</td>
                                            <td>{data.city}</td>
                                            <td>{data.barangay}</td>
                                            <td>{data.purok?.purok_name || "N/A"}</td>
                                            <td>
                                        <span
                                            className={`badge ${
                                                data.is_resident === "YES"
                                                    ? "bg-success"
                                                    : "bg-secondary"
                                            }`}
                                        >
                                            {data.is_resident}
                                        </span>
                                            </td>
                                            <td>
                                        <span
                                            className={`badge ${
                                                data.is_head_of_family === "YES"
                                                    ? "bg-success"
                                                    : "bg-secondary"
                                            }`}
                                        >
                                            {data.is_head_of_family}
                                        </span>
                                            </td>
                                            <td>{data.total_beneficiaries || "N/A"}</td>
                                            <td>
                                                <button
                                                    className="btn btn-warning btn-sm me-2"
                                                    onClick={() => {
                                                        setFormData({
                                                            id: data.id,
                                                            lastname: data.lastname,
                                                            middlename: data.middlename,
                                                            firstname: data.firstname,
                                                            suffix: data.suffix,
                                                            gender: data.gender,
                                                            region: data.region,
                                                            province: data.province,
                                                            city: data.city,
                                                            barangay: data.barangay,
                                                            is_resident: data.is_resident,
                                                            total_beneficiaries: data.total_beneficiaries,
                                                            relationship: data.household_details?.relationship || "N/A",
                                                        });
                                                        setIsMember(data.is_head_of_family);
                                                        setSelectedRegion({name: data.region});
                                                        setSelectedCity({name: data.city});
                                                        setSelectedProvince({name: data.province});
                                                        setSelectedBarangay({name: data.barangay});
                                                        setSelectedPurok({
                                                            id: data.purok?.id || null,
                                                            purok_name: data.purok?.purok_name || "N/A",
                                                        });
                                                        setSelectedHead({
                                                            id: data.household_details?.id || null,
                                                            person: {
                                                                lastname: data.household_details?.lastname || "N/A",
                                                                middlename: data.household_details?.middlename || "N/A",
                                                                firstname: data.household_details?.firstname || "N/A",
                                                                suffix: data.household_details?.suffix || "",
                                                                purok: {
                                                                    purok_name: data.household_details?.purok || "N/A"
                                                                }
                                                            }

                                                        });
                                                        setShowEditModalPerson(true);
                                                    }}
                                                >
                                                    <i className="fa fa-edit"></i>
                                                </button>
                                                <button
                                                    className="btn btn-danger btn-sm"
                                                    onClick={() =>
                                                        confirmDeletePerson(
                                                            data.id,
                                                            `${data.lastname}, ${data.middlename} ${data.firstname} ${data.suffix}`
                                                        )
                                                    }
                                                >
                                                    <i className="fa fa-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                                </tbody>
                            </table>
                        </div>

                        {/* Pagination */}
                        <ReactPaginate
                            pageCount={pageCountPerson}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChangePerson}
                            containerClassName="pagination justify-content-center mt-4"
                            activeClassName="active"
                            pageLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            breakLinkClassName="page-link"
                            pageClassName="page-item"
                            previousClassName="page-item"
                            nextClassName="page-item"
                            breakClassName="page-item"
                            disabledClassName="disabled"
                        />
                    </div>
                </div>
            </div>


            <Modal
                size="lg"
                show={showAddModalPerson}
                onHide={() => setAddModalPerson(false)}
                aria-labelledby="modal-add-person"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="modal-add-person" className="text-primary">
                        <i className="fa fa-user-plus me-2"></i>Add New Person
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={confirmAddPerson}>
                        <Row className="mb-3">
                            <Col>
                                <label htmlFor="firstname" className="form-label fw-bold">
                                    First Name <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="firstname"
                                    id="firstname"
                                    placeholder="Enter first name"
                                    required
                                />
                            </Col>
                            <Col>
                                <label htmlFor="middlename" className="form-label fw-bold">
                                    Middle Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="middlename"
                                    id="middlename"
                                    placeholder="Enter middle name"
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <label htmlFor="lastname" className="form-label fw-bold">
                                    Last Name <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="lastname"
                                    id="lastname"
                                    placeholder="Enter last name"
                                    required
                                />
                            </Col>
                            <Col>
                                <label htmlFor="suffix" className="form-label fw-bold">
                                    Suffix
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="suffix"
                                    id="suffix"
                                    placeholder="Enter suffix (e.g., Jr., Sr.)"
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <label htmlFor="gender" className="form-label fw-bold">
                                    Gender <span className="text-danger">*</span>
                                </label>
                                <select
                                    className="form-select"
                                    name="gender"
                                    id="gender"
                                    defaultValue=""
                                    required
                                >
                                    <option value="" disabled>
                                        Select gender
                                    </option>
                                    <option value="MALE">Male</option>
                                    <option value="FEMALE">Female</option>
                                </select>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <label htmlFor="region" className="form-label fw-bold">
                                    Region <span className="text-danger">*</span>
                                </label>
                                <Typeahead
                                    id="region"
                                    labelKey="name"
                                    options={regions}
                                    placeholder="Choose a region"
                                    onChange={handleRegionChange}
                                    selected={selectedRegion ? [selectedRegion] : []}
                                />
                            </Col>
                            <Col>
                                <label htmlFor="province" className="form-label fw-bold">
                                    Province <span className="text-danger">*</span>
                                </label>
                                <Typeahead
                                    id="province"
                                    labelKey="name"
                                    options={provinces}
                                    placeholder="Choose a province"
                                    onChange={handleProvinceChange}
                                    selected={selectedProvince ? [selectedProvince] : []}
                                    disabled={!selectedRegion}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <label htmlFor="city" className="form-label fw-bold">
                                    City <span className="text-danger">*</span>
                                </label>
                                <Typeahead
                                    id="city"
                                    labelKey="name"
                                    options={cities}
                                    placeholder="Choose a city"
                                    onChange={handleCityChange}
                                    selected={selectedCity ? [selectedCity] : []}
                                    disabled={!selectedProvince}
                                />
                            </Col>
                            <Col>
                                <label htmlFor="barangay" className="form-label fw-bold">
                                    Barangay <span className="text-danger">*</span>
                                </label>
                                <Typeahead
                                    id="barangay"
                                    labelKey="name"
                                    options={barangays}
                                    placeholder="Choose a barangay"
                                    onChange={handleBarangayChange}
                                    selected={selectedBarangay ? [selectedBarangay] : []}
                                    disabled={!selectedCity}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <label htmlFor="purok" className="form-label fw-bold">
                                    Purok <span className="text-danger">*</span>
                                </label>
                                <Typeahead
                                    id="purok"
                                    labelKey="purok_name"
                                    options={puroks}
                                    placeholder="Choose a purok"
                                    onChange={handlePurokChange}
                                    selected={selectedPurok ? [selectedPurok] : []}
                                    disabled={!selectedBarangay}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <label htmlFor="is_resident" className="form-label fw-bold">
                                    Is Resident? <span className="text-danger">*</span>
                                </label>
                                <select
                                    className="form-select"
                                    name="is_resident"
                                    id="is_resident"
                                    defaultValue=""
                                    required
                                >
                                    <option value="" disabled>
                                        Select
                                    </option>
                                    <option value="YES">Yes</option>
                                    <option value="NO">No</option>
                                </select>
                            </Col>
                            <Col>
                                <label htmlFor="is_head_of_family" className="form-label fw-bold">
                                    Is Head of Family? <span className="text-danger">*</span>
                                </label>
                                <select
                                    className="form-select"
                                    name="is_head_of_family"
                                    id="is_head_of_family"
                                    defaultValue=""
                                    required
                                    value={isMember}
                                    onChange={handleIsMemberChange}
                                >
                                    <option value="" disabled>
                                        Select
                                    </option>
                                    <option value="YES">Yes</option>
                                    <option value="NO">No</option>
                                </select>
                            </Col>
                        </Row>

                        {isMember === "YES" && (
                            <Row className="mb-3">
                                <Col>
                                    <label
                                        htmlFor="total_beneficiaries"
                                        className="form-label fw-bold"
                                    >
                                        Total Beneficiaries (18 and Above) <span className="text-danger">*</span>
                                    </label>
                                    <NumericFormat
                                        className="form-control"
                                        thousandSeparator
                                        allowNegative={false}
                                        name="total_beneficiaries"
                                        id="total_beneficiaries"
                                        required
                                    />
                                </Col>
                            </Row>
                        )}

                        {isMember === "NO" && (
                            <>
                                <Row className="mb-3">
                                    <Col>
                                        <label htmlFor="person" className="form-label fw-bold">
                                            Head Of The Family Person <span className="text-danger">*</span>
                                        </label>
                                        <Typeahead
                                            id="person"
                                            labelKey={(option) =>
                                                `${option.person.lastname}, ${option.person.middlename || ""} ${option.person.firstname} ${option.person.suffix || ""} - ${option.person.purok.purok_name}`.trim()
                                            }
                                            options={head}
                                            paginate
                                            maxResults={10}
                                            isLoading={isLoadingHead}
                                            onInputChange={handleInputChangeHead}
                                            onPaginate={() => {
                                                if (head.length < totalHead) {
                                                    fetchHead(pageHead + 1, searchHead);
                                                    setPageHead((prev) => prev + 1);
                                                }
                                            }}
                                            placeholder="Search for a head of the family person..."
                                            selected={selectedHead ? [selectedHead] : []}
                                            onChange={handleHeadChange}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <label htmlFor="relationship" className="form-label fw-bold">
                                            Relationship <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="relationship"
                                            id="relationship"
                                            placeholder="Enter relationship"
                                            required
                                        />
                                    </Col>
                                </Row>
                            </>

                        )}

                        <div className="d-flex justify-content-end">
                            <button
                                type="submit"
                                className="btn btn-primary mt-3"
                                disabled={isModifying}
                            >
                                {isModifying ? <i className="fa fa-spinner fa-spin"></i> : "Add"}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>


            <Modal
                show={showAddConfrimModalPerson}
                onHide={() => setAddConfirmModalPerson(false)}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-primary">
                        <i className="fa fa-info-circle me-2"></i>Confirm Person Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="mb-3">
                        <Col>
                            <h6 className="text-muted">Personal Information</h6>
                            <p>
                                <strong>First Name:</strong> {formData.firstname || "N/A"}
                            </p>
                            <p>
                                <strong>Middle Name:</strong> {formData.middlename || "N/A"}
                            </p>
                            <p>
                                <strong>Last Name:</strong> {formData.lastname || "N/A"}
                            </p>
                            <p>
                                <strong>Suffix:</strong> {formData.suffix || "N/A"}
                            </p>
                            <p>
                                <strong>Gender:</strong> {formData.gender || "N/A"}
                            </p>
                        </Col>
                        <Col>
                            <h6 className="text-muted">Location Information</h6>
                            <p>
                                <strong>Region:</strong> {formData.region || "N/A"}
                            </p>
                            <p>
                                <strong>Province:</strong> {formData.province || "N/A"}
                            </p>
                            <p>
                                <strong>City:</strong> {formData.city || "N/A"}
                            </p>
                            <p>
                                <strong>Barangay:</strong> {formData.barangay || "N/A"}
                            </p>
                            <p>
                                <strong>Purok:</strong> {formData.purok_name || "N/A"}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h6 className="text-muted">Additional Details</h6>
                            <p>
                                <strong>Is Resident:</strong> {formData.is_resident || "N/A"}
                            </p>
                            <p>
                                <strong>Is Head of Family:</strong> {formData.is_head_of_family || "N/A"}
                            </p>
                            {isMember === "YES" && (
                                <p>
                                    <strong>Total Beneficiaries:</strong> {formData.total_beneficiaries || "0"}
                                </p>
                            )}
                            {isMember === "NO" && (
                                <>
                                    <p>
                                        <strong>Head of Family Person:</strong> {formData.head_name || "N/A"}
                                    </p>
                                    <p>
                                        <strong>Relationship:</strong> {formData.relationship || "N/A"}
                                    </p>
                                </>


                            )}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setAddConfirmModalPerson(false);
                            setModifying(false);
                        }}
                    >
                        <i className="fa fa-times me-1"></i>Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            setAddConfirmModalPerson(false);
                            handleAddPerson();
                        }}
                    >
                        <i className="fa fa-check me-1"></i>Confirm
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal
                show={showDeleteModalPerson}
                onHide={() => setShowDeleteModalPerson(false)}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-danger">
                        <i className="fa fa-trash me-2"></i>Delete Person
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-center fs-5">
                        Are you sure you want to delete the person:
                        <span className="fw-bold text-danger"> {deletePersonName || "N/A"}?</span>
                    </p>
                    <p className="text-center text-muted">
                        This action cannot be undone.
                    </p>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button
                        variant="secondary"
                        onClick={() => setShowDeleteModalPerson(false)}
                        className="px-4"
                    >
                        <i className="fa fa-times me-1"></i>Cancel
                    </Button>
                    <Button
                        variant="danger"
                        onClick={() => {
                            handleDeletePerson(deletePersonId);
                            setShowDeleteModalPerson(false);
                        }}
                        className="px-4"
                    >
                        <i className="fa fa-trash me-1"></i>Delete
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal
                size="lg"
                show={showEditModalPerson}
                onHide={() => setShowEditModalPerson(false)}
                aria-labelledby="modal-edit-person"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="modal-edit-person" className="text-warning">
                        <i className="fa fa-user-edit me-2"></i>Edit Person
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={confirmEditPerson}>
                        <input
                            type="hidden"
                            name="id"
                            id="id"
                            value={formData.id}
                            readOnly
                        />

                        <Row className="mb-3">
                            <Col>
                                <label htmlFor="firstname" className="form-label fw-bold">
                                    First Name <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="firstname"
                                    id="firstname"
                                    value={formData.firstname || ''}
                                    onChange={(e) => setFormData({...formData, firstname: e.target.value})}
                                    placeholder="Enter first name"
                                    required
                                />
                            </Col>
                            <Col>
                                <label htmlFor="middlename" className="form-label fw-bold">
                                    Middle Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="middlename"
                                    id="middlename"
                                    value={formData.middlename || ''}
                                    onChange={(e) => setFormData({...formData, middlename: e.target.value})}
                                    placeholder="Enter middle name"
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <label htmlFor="lastname" className="form-label fw-bold">
                                    Last Name <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="lastname"
                                    id="lastname"
                                    value={formData.lastname || ''}
                                    onChange={(e) => setFormData({...formData, lastname: e.target.value})}
                                    placeholder="Enter last name"
                                    required
                                />
                            </Col>
                            <Col>
                                <label htmlFor="suffix" className="form-label fw-bold">
                                    Suffix
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="suffix"
                                    id="suffix"
                                    value={formData.suffix || ''}
                                    onChange={(e) => setFormData({...formData, suffix: e.target.value})}
                                    placeholder="Enter suffix (e.g., Jr., Sr.)"
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <label htmlFor="gender" className="form-label fw-bold">
                                    Gender <span className="text-danger">*</span>
                                </label>
                                <select
                                    className="form-select"
                                    name="gender"
                                    id="gender"
                                    value={formData.gender || ''}
                                    onChange={(e) => setFormData({...formData, gender: e.target.value})}
                                    required
                                >
                                    <option value="" disabled>
                                        Select gender
                                    </option>
                                    <option value="MALE">Male</option>
                                    <option value="FEMALE">Female</option>
                                </select>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <label htmlFor="region" className="form-label fw-bold">
                                    Region <span className="text-danger">*</span>
                                </label>
                                <Typeahead
                                    id="region"
                                    labelKey="name"
                                    options={regions}
                                    placeholder="Choose a region"
                                    onChange={handleRegionChange}
                                    selected={selectedRegion ? [selectedRegion] : []}
                                />
                            </Col>
                            <Col>
                                <label htmlFor="province" className="form-label fw-bold">
                                    Province <span className="text-danger">*</span>
                                </label>
                                <Typeahead
                                    id="province"
                                    labelKey="name"
                                    options={provinces}
                                    placeholder="Choose a province"
                                    onChange={handleProvinceChange}
                                    selected={selectedProvince ? [selectedProvince] : []}
                                    disabled={!selectedRegion}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <label htmlFor="city" className="form-label fw-bold">
                                    City <span className="text-danger">*</span>
                                </label>
                                <Typeahead
                                    id="city"
                                    labelKey="name"
                                    options={cities}
                                    placeholder="Choose a city"
                                    onChange={handleCityChange}
                                    selected={selectedCity ? [selectedCity] : []}
                                    disabled={!selectedProvince}
                                />
                            </Col>
                            <Col>
                                <label htmlFor="barangay" className="form-label fw-bold">
                                    Barangay <span className="text-danger">*</span>
                                </label>
                                <Typeahead
                                    id="barangay"
                                    labelKey="name"
                                    options={barangays}
                                    placeholder="Choose a barangay"
                                    onChange={handleBarangayChange}
                                    selected={selectedBarangay ? [selectedBarangay] : []}
                                    disabled={!selectedCity}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <label htmlFor="purok" className="form-label fw-bold">
                                    Purok <span className="text-danger">*</span>
                                </label>
                                <Typeahead
                                    id="purok"
                                    labelKey="purok_name"
                                    options={puroks}
                                    placeholder="Choose a purok"
                                    onChange={handlePurokChange}
                                    selected={selectedPurok ? [selectedPurok] : []}
                                    disabled={!selectedBarangay}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <label htmlFor="is_resident" className="form-label fw-bold">
                                    Is Resident? <span className="text-danger">*</span>
                                </label>
                                <select
                                    className="form-select"
                                    name="is_resident"
                                    id="is_resident"
                                    value={formData.is_resident || ''}
                                    onChange={(e) => setFormData({...formData, is_resident: e.target.value})}
                                    required
                                >
                                    <option value="" disabled>
                                        Select
                                    </option>
                                    <option value="YES">Yes</option>
                                    <option value="NO">No</option>
                                </select>
                            </Col>
                            <Col>
                                <label htmlFor="is_head_of_family" className="form-label fw-bold">
                                    Is Head of Family? <span className="text-danger">*</span>
                                </label>
                                <select
                                    className="form-select"
                                    name="is_head_of_family"
                                    id="is_head_of_family"
                                    value={isMember || ''}
                                    onChange={handleIsMemberChange}
                                    required
                                >
                                    <option value="" disabled>
                                        Select
                                    </option>
                                    <option value="YES">Yes</option>
                                    <option value="NO">No</option>
                                </select>
                            </Col>
                        </Row>

                        {isMember === "YES" && (
                            <Row className="mb-3">
                                <Col>
                                    <label htmlFor="total_beneficiaries" className="form-label fw-bold">
                                        Total Beneficiaries (18 and Above) <span className="text-danger">*</span>
                                    </label>
                                    <NumericFormat
                                        className="form-control"
                                        thousandSeparator
                                        allowNegative={false}
                                        name="total_beneficiaries"
                                        id="total_beneficiaries"
                                        value={formData.total_beneficiaries || ''}
                                        onChange={(e) =>
                                            setFormData({...formData, total_beneficiaries: e.target.value})
                                        }
                                        required
                                    />
                                </Col>
                            </Row>
                        )}

                        {isMember === "NO" && (
                            <>
                                <Row className="mb-3">
                                    <Col>
                                        <label htmlFor="person" className="form-label fw-bold">
                                            Head Of The Family Person <span className="text-danger">*</span>
                                        </label>
                                        <Typeahead
                                            id="person"
                                            labelKey={(option) =>
                                                `${option.person.lastname}, ${option.person.middlename || ""} ${option.person.firstname} ${option.person.suffix || ""} - ${option.person.purok.purok_name}`.trim()
                                            }
                                            options={head}
                                            paginate
                                            maxResults={10}
                                            isLoading={isLoadingHead}
                                            onInputChange={handleInputChangeHead}
                                            onPaginate={() => {
                                                if (head.length < totalHead) {
                                                    fetchHead(pageHead + 1, searchHead);
                                                    setPageHead((prev) => prev + 1);
                                                }
                                            }}
                                            placeholder="Search for a head of the family person..."
                                            selected={selectedHead ? [selectedHead] : []}
                                            onChange={handleHeadChange}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <label htmlFor="relationship" className="form-label fw-bold">
                                            Relationship <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="relationship"
                                            id="relationship"
                                            placeholder="Enter relationship"
                                            required
                                            value={formData.relationship || ''}
                                            onChange={(e) =>
                                                setFormData({...formData, relationship: e.target.value})
                                            }
                                        />
                                    </Col>
                                </Row>
                            </>

                        )}

                        <div className="d-flex justify-content-end">
                            <button
                                type="submit"
                                className="btn btn-warning mt-3"
                                disabled={isModifying}
                            >
                                {isModifying ? <i className="fa fa-spinner fa-spin"></i> : "Update"}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>


            <Modal
                show={showEditConfrimModalPerson}
                onHide={() => setEditConfirmModalPerson(false)}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-warning">
                        <i className="fa fa-exclamation-circle me-2"></i>Confirm Edited Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="mb-3">
                        <Col>
                            <h6 className="text-muted">Personal Information</h6>
                            <p>
                                <strong>First Name:</strong> {formData.firstname || "N/A"}
                            </p>
                            <p>
                                <strong>Middle Name:</strong> {formData.middlename || "N/A"}
                            </p>
                            <p>
                                <strong>Last Name:</strong> {formData.lastname || "N/A"}
                            </p>
                            <p>
                                <strong>Suffix:</strong> {formData.suffix || "N/A"}
                            </p>
                            <p>
                                <strong>Gender:</strong> {formData.gender || "N/A"}
                            </p>
                        </Col>
                        <Col>
                            <h6 className="text-muted">Location Information</h6>
                            <p>
                                <strong>Region:</strong> {formData.region || "N/A"}
                            </p>
                            <p>
                                <strong>Province:</strong> {formData.province || "N/A"}
                            </p>
                            <p>
                                <strong>City:</strong> {formData.city || "N/A"}
                            </p>
                            <p>
                                <strong>Barangay:</strong> {formData.barangay || "N/A"}
                            </p>
                            <p>
                                <strong>Purok:</strong> {formData.purok_name || "N/A"}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h6 className="text-muted">Additional Details</h6>
                            <p>
                                <strong>Is Resident:</strong> {formData.is_resident || "N/A"}
                            </p>
                            <p>
                                <strong>Is Head of Family:</strong> {formData.is_head_of_family || "N/A"}
                            </p>
                            {isMember === "YES" && (
                                <p>
                                    <strong>Total Beneficiaries:</strong> {formData.total_beneficiaries || "0"}
                                </p>
                            )}
                            {isMember === "NO" && (
                                <>
                                    <p>
                                        <strong>Head of Family Person:</strong> {formData.head_name || "N/A"}
                                    </p>
                                    <p>
                                        <strong>Relationship:</strong> {formData.relationship || "N/A"}
                                    </p>
                                </>


                            )}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setEditConfirmModalPerson(false);
                            setModifying(false);
                        }}
                    >
                        <i className="fa fa-times me-1"></i>Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            setEditConfirmModalPerson(false);
                            handleEditPerson();
                        }}
                    >
                        <i className="fa fa-check me-1"></i>Confirm
                    </Button>
                </Modal.Footer>
            </Modal>


        </>
    )
}

export default Person