import Cookies from "js-cookie";
import ReactPaginate from "react-paginate";
import {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import Loading from "../others/Loading";
import {Button, Col, Modal, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {Typeahead} from "react-bootstrap-typeahead";

const Events = () => {
    const token = Cookies.get('token');
    const [formData, setFormData] = useState({});
    const [isModifying, setModifying] = useState(false);
    const navigate = useNavigate();

    const [pageNumberEvent, setPageNumberEvent] = useState(0);
    const [pageSizeEvent, setPageSizeEvent] = useState(10);
    const [totalEvent, setTotalEvent] = useState(0);
    const [searchTermEvent, setSearchTermEvent] = useState('');
    const [isLoadingEvent, setLoadingEvent] = useState(true)
    const [event, setEvent] = useState([])
    const [showAddModalEvent, setAddModalEvent] = useState(false);
    const [showAddConfrimModalEvent, setAddConfirmModalEvent] = useState(false);
    const pageCountEvent = Math.ceil(totalEvent / pageSizeEvent);
    const [deleteEventId, setDeleteEventId] = useState(null);
    const [deleteEventName, setDeleteEventName] = useState(null);
    const [showDeleteModalEvent, setShowDeleteModalEvent] = useState(false);

    const [selectedEventId, setSelectedEventId] = useState(null);
    const [confirmAction, setConfirmAction] = useState(null);
    const [showConfirmModalChangeStatus, setShowConfirmModalChangeStatus] = useState(false);

    const [showEditModalEvent, setShowEditModalEvent] = useState(false);
    const [showEditConfrimModalEvent, setEditConfirmModalEvent] = useState(false);

    const [eventType, setEventTypes] = useState([]);
    const [selectedEventType, setSelectedEventType] = useState(null);


    useEffect(() => {
        fetchEventTypes()
    }, []);

    const fetchEventTypes = () => {
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/eventType-typeahead`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            )
            .then((response) => {
                setEventTypes(response.data.data);
            });
    };

    const handleEventTypeChange = (selected) => {
        if (selected.length > 0) {
            setSelectedEventType(selected[0]);
        } else {
            setSelectedEventType(null);
        }
    };


    const handlePageChangeEvent = ({selected}) => {
        setPageNumberEvent(selected);
    };

    useEffect(() => {
        fetchEvent();
    }, [token, pageNumberEvent, pageSizeEvent, searchTermEvent]);

    useEffect(() => {
        setPageNumberEvent(0); // Reset the page number when search term changes
    }, [searchTermEvent]);

    const fetchEvent = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/events?page=${pageNumberEvent + 1}&page_size=${pageSizeEvent}&search=${searchTermEvent}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setEvent(response.data.data);
                setTotalEvent(response.data.total); // Ensure total is set correctly
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setLoadingEvent(false)
            })
    };

    function confirmAddEvent(event) {
        event.preventDefault();
        setModifying(true);

        const formData = new FormData(event.target);

        const data = {
            event_name: formData.get("event_name"),
            event_date: formData.get("event_date"),
            event_type: selectedEventType.event_type,
            event_type_id: selectedEventType.id,

        };

        setFormData(data);
        setAddConfirmModalEvent(true);
    }

    function handleAddEvent() {
        axios.post(`${process.env.REACT_APP_API_URL}/add-event`, formData, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                fetchEvent()
                toast.success(response.data.message)
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setModifying(false)
                setAddModalEvent(false)
                setSelectedEventType(null)
            })
    }


    function confirmDeleteEvent(id, event_name) {
        setDeleteEventId(id);
        setDeleteEventName(`${event_name}`);
        setShowDeleteModalEvent(true);
    }

    function handleDeleteEvent(id) {
        axios.delete(`${process.env.REACT_APP_API_URL}/delete-event/${id}`, {
            method: 'DELETE', headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                fetchEvent()
                toast.success(response.data.message)
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            });
    }

    const handleConfirmChangeStatus = (id, action) => {
        setSelectedEventId(id);
        setConfirmAction(action);
        setShowConfirmModalChangeStatus(true);
    };

    const handleChangeStatus = () => {
        axios.put(`${process.env.REACT_APP_API_URL}/change-event-status/${selectedEventId}`, {status: confirmAction}, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                toast.success(response.data.message);
                fetchEvent();
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setShowConfirmModalChangeStatus(false);
            });
    };


    function confirmEditEvent(event) {
        event.preventDefault()
        setModifying(true)

        const formData = new FormData(event.target);

        const data = {
            id: formData.get('id'),
            event_name: formData.get("event_name"),
            event_date: formData.get("event_date"),
        };
        setFormData(data);
        setEditConfirmModalEvent(true);
    }

    function handleEditEvent() {
        axios.put(`${process.env.REACT_APP_API_URL}/update-event`, formData, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                fetchEvent()
                toast.success(response.data.message)
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setModifying(false)
                setShowEditModalEvent(false)
            })
    }

    function handleAttendees(id, event_name) {
        const encodedEventName = encodeURIComponent(event_name);
        navigate(`/portal/event/attendees/${id}/${encodedEventName}`);
    }

    function handleEventReports() {
        navigate(`/portal/events/reports`);
    }

    if (isLoadingEvent) {
        return (<Loading/>);
    }


    return (
        <>
            <div className="container-fluid">
                <h3 className="text-center text-white bg-dark py-3 rounded mt-3">
                    <i className="fa fa-calendar-alt me-2"></i>Events
                </h3>
                <div className="text-center mb-3 mt-3">
                    <button
                        className="btn btn-warning"
                        onClick={() =>
                            handleEventReports()
                        }
                    >
                        <i className="fa fa-file me-1"></i>Event Reports
                    </button>
                </div>

                <div className="card shadow border-0">
                    <div className="card-header d-flex justify-content-between align-items-center bg-light">
                        <h5 className="text-primary fw-bold mb-0">
                            <i className="fa fa-info-circle me-2"></i>Events Information
                        </h5>
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => setAddModalEvent(true)}
                        >
                            <i className="fa fa-plus me-2"></i>Add New Event
                        </button>
                    </div>
                    <div className="card-body">
                        {/* Search and Page Size */}
                        <div className="row mb-4">
                            <div className="col-md-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search Events"
                                    value={searchTermEvent}
                                    onChange={(e) => setSearchTermEvent(e.target.value)}
                                />
                            </div>
                            <div className="col-md-2">
                                <select
                                    className="form-select"
                                    value={pageSizeEvent}
                                    onChange={(e) => {
                                        setPageSizeEvent(Number(e.target.value));
                                        setPageNumberEvent(0);
                                    }}
                                >
                                    {[10, 20, 30, 40, 50].map((size) => (
                                        <option key={size} value={size}>
                                            Show {size} per page
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        {/* Events Table */}
                        <div className="table-responsive">
                            <table className="table table-bordered table-hover align-middle">
                                <thead className="table-primary">
                                <tr>
                                    <th>#</th>
                                    <th>Event Name</th>
                                    <th>Event Type</th>
                                    <th>Event Date</th>
                                    <th>Status</th>
                                    <th>Total Attendees</th>
                                    <th>Total Household Attendees</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {event.length === 0 ? (
                                    <tr>
                                        <td colSpan="100" className="text-center text-muted">
                                            No results found.
                                        </td>
                                    </tr>
                                ) : (
                                    event.map((data, index) => (
                                        <tr key={data.id}>
                                            <td>{index + 1 + pageNumberEvent * pageSizeEvent}</td>
                                            <td>{data.event_name}</td>
                                            <td>{data.event_type.event_type}</td>
                                            <td>{new Date(data.event_date).toLocaleDateString()}</td>
                                            <td>
                                        <span
                                            className={`badge ${
                                                data.status === "ACTIVE"
                                                    ? "bg-success"
                                                    : "bg-secondary"
                                            }`}
                                        >
                                            {data.status}
                                        </span>
                                            </td>
                                            <td>{data.total_attendees}</td>
                                            <td>{data.total_household_attendees}</td>
                                            <td>
                                                <button
                                                    className="btn btn-warning btn-sm me-2"
                                                    onClick={() => {
                                                        setFormData({
                                                            id: data.id,
                                                            event_name: data.event_name,
                                                            event_date: data.event_date,
                                                        });
                                                        setShowEditModalEvent(true);
                                                    }}
                                                >
                                                    <i className="fa fa-edit"></i>
                                                </button>
                                                <button
                                                    className="btn btn-danger btn-sm me-2"
                                                    onClick={() =>
                                                        confirmDeleteEvent(data.id, data.event_name)
                                                    }
                                                >
                                                    <i className="fa fa-trash"></i>
                                                </button>
                                                <button
                                                    className={`btn btn-sm me-2 ${
                                                        data.status === "ACTIVE"
                                                            ? "btn-dark"
                                                            : "btn-success"
                                                    }`}
                                                    onClick={() =>
                                                        handleConfirmChangeStatus(
                                                            data.id,
                                                            data.status === "ACTIVE"
                                                                ? "INACTIVE"
                                                                : "ACTIVE"
                                                        )
                                                    }
                                                >
                                                    <i
                                                        className={`fa ${
                                                            data.status === "ACTIVE"
                                                                ? "fa-toggle-off"
                                                                : "fa-toggle-on"
                                                        }`}
                                                    ></i>
                                                </button>
                                                <button
                                                    className="btn btn-info btn-sm"
                                                    onClick={() =>
                                                        handleAttendees(data.id, data.event_name)
                                                    }
                                                >
                                                    <i className="fa fa-users me-1"></i>Attendees
                                                </button>

                                            </td>
                                        </tr>
                                    ))
                                )}
                                </tbody>
                            </table>
                        </div>

                        {/* Pagination */}
                        <ReactPaginate
                            pageCount={pageCountEvent}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChangeEvent}
                            containerClassName="pagination justify-content-center mt-4"
                            activeClassName="active"
                            pageLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            breakLinkClassName="page-link"
                            pageClassName="page-item"
                            previousClassName="page-item"
                            nextClassName="page-item"
                            breakClassName="page-item"
                            disabledClassName="disabled"
                        />
                    </div>
                </div>
            </div>


            <Modal
                size="lg"
                show={showAddModalEvent}
                onHide={() => setAddModalEvent(false)}
                backdrop="static"
                centered
                aria-labelledby="modal-add-event"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="modal-add-event" className="text-primary">
                        <i className="fa fa-calendar-plus me-2"></i>Add New Event
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={confirmAddEvent}>
                        <Row className="mb-3">
                            <Col>
                                <label htmlFor="event_name" className="form-label fw-bold">
                                    Event Name <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="event_name"
                                    id="event_name"
                                    placeholder="Enter event name"
                                    required
                                />
                                <label htmlFor="event_type" className="form-label fw-bold">
                                    Event Type <span className="text-danger">*</span>
                                </label>
                                <Typeahead
                                    id="event_type"
                                    labelKey="event_type"
                                    options={eventType}
                                    placeholder="Choose a event type"
                                    onChange={handleEventTypeChange}
                                    selected={selectedEventType ? [selectedEventType] : []}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <label htmlFor="event_date" className="form-label fw-bold">
                                    Event Date <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="event_date"
                                    id="event_date"
                                    required
                                />
                            </Col>
                        </Row>
                        <div className="d-flex justify-content-end">
                            <button
                                type="submit"
                                className="btn btn-primary px-4"
                                disabled={isModifying}
                            >
                                {isModifying ? (
                                    <i className="fa fa-spinner fa-spin"></i>
                                ) : (
                                    <i className="fa fa-plus me-1"></i>
                                )}
                                Add Event
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal
                show={showAddConfrimModalEvent}
                onHide={() => setAddConfirmModalEvent(false)}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-primary">
                        <i className="fa fa-info-circle me-2"></i>Confirm Event Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="mb-3">
                        <Col>
                            <p>
                                <strong>Event Name:</strong> {formData.event_name || "N/A"}
                            </p>
                            <p>
                                <strong>Event Type:</strong> {formData.event_type || "N/A"}
                            </p>
                            <p>
                                <strong>Event Date:</strong>{" "}
                                {formData.event_date
                                    ? new Date(formData.event_date).toLocaleDateString()
                                    : "N/A"}
                            </p>

                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setAddConfirmModalEvent(false);
                            setModifying(false);
                        }}
                        className="px-4"
                    >
                        <i className="fa fa-times me-1"></i>Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            setAddConfirmModalEvent(false);
                            handleAddEvent();
                        }}
                        className="px-4"
                    >
                        <i className="fa fa-check me-1"></i>Confirm
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal
                show={showDeleteModalEvent}
                onHide={() => setShowDeleteModalEvent(false)}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-danger">
                        <i className="fa fa-trash me-2"></i>Delete Event
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-center fs-5">
                        Are you sure you want to delete the event:
                        <span className="fw-bold text-danger"> {deleteEventName || "N/A"}?</span>
                    </p>
                    <p className="text-center text-muted">
                        This action cannot be undone.
                    </p>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button
                        variant="secondary"
                        onClick={() => setShowDeleteModalEvent(false)}
                        className="px-4"
                    >
                        <i className="fa fa-times me-1"></i>Cancel
                    </Button>
                    <Button
                        variant="danger"
                        onClick={() => {
                            handleDeleteEvent(deleteEventId);
                            setShowDeleteModalEvent(false);
                        }}
                        className="px-4"
                    >
                        <i className="fa fa-trash me-1"></i>Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showConfirmModalChangeStatus}
                onHide={() => setShowConfirmModalChangeStatus(false)}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-warning">
                        <i className="fa fa-exclamation-circle me-2"></i>Confirm Status Change
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-center fs-5">
                        Are you sure you want to change the status of this event to{" "}
                        <span
                            className={`fw-bold ${
                                confirmAction === "ACTIVE" ? "text-success" : "text-danger"
                            }`}
                        >
                {confirmAction === "ACTIVE" ? "ACTIVE" : "INACTIVE"}
            </span>
                        ?
                    </p>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button
                        variant="secondary"
                        onClick={() => setShowConfirmModalChangeStatus(false)}
                        className="px-4"
                    >
                        <i className="fa fa-times me-1"></i>Cancel
                    </Button>
                    <Button
                        variant={confirmAction === "ACTIVE" ? "success" : "danger"}
                        onClick={handleChangeStatus}
                        className="px-4"
                    >
                        <i
                            className={`fa ${
                                confirmAction === "ACTIVE" ? "fa-toggle-on" : "fa-toggle-off"
                            } me-1`}
                        ></i>
                        {confirmAction === "ACTIVE" ? "Activate" : "Deactivate"}
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal
                size="lg"
                show={showEditModalEvent}
                onHide={() => setShowEditModalEvent(false)}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-warning">
                        <i className="fa fa-edit me-2"></i>Edit Event
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={confirmEditEvent}>
                        <input
                            type="hidden"
                            name="id"
                            id="id"
                            value={formData.id}
                            readOnly
                        />
                        <Row className="mb-3">
                            <Col>
                                <label htmlFor="event_name" className="form-label fw-bold">
                                    Event Name <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="event_name"
                                    id="event_name"
                                    value={formData.event_name || ''}
                                    onChange={(e) =>
                                        setFormData({...formData, event_name: e.target.value})
                                    }
                                    placeholder="Enter event name"
                                    required
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <label htmlFor="event_date" className="form-label fw-bold">
                                    Event Date <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="event_date"
                                    id="event_date"
                                    value={formData.event_date || ''}
                                    onChange={(e) =>
                                        setFormData({...formData, event_date: e.target.value})
                                    }
                                    required
                                />
                            </Col>
                        </Row>
                        <div className="d-flex justify-content-end">
                            <button
                                type="submit"
                                className="btn btn-warning px-4"
                                disabled={isModifying}
                            >
                                {isModifying ? (
                                    <i className="fa fa-spinner fa-spin"></i>
                                ) : (
                                    <i className="fa fa-save me-1"></i>
                                )}
                                Update
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>


            <Modal
                show={showEditConfrimModalEvent}
                onHide={() => setEditConfirmModalEvent(false)}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-warning">
                        <i className="fa fa-info-circle me-2"></i>Confirm Edited Event Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <h6 className="text-muted">Event Details</h6>
                            <p>
                                <strong>Event Name:</strong> {formData.event_name || "N/A"}
                            </p>
                            <p>
                                <strong>Event Date:</strong> {formData.event_date || "N/A"}
                            </p>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setEditConfirmModalEvent(false);
                            setModifying(false);
                        }}
                        className="px-4"
                    >
                        <i className="fa fa-times me-1"></i>Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            setEditConfirmModalEvent(false);
                            handleEditEvent();
                        }}
                        className="px-4"
                    >
                        <i className="fa fa-check me-1"></i>Confirm
                    </Button>
                </Modal.Footer>
            </Modal>


        </>
    )
}

export default Events