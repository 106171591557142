import Cookies from "js-cookie";
import ReactPaginate from "react-paginate";
import {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import {Button, Col, Modal, Row} from "react-bootstrap";
import Loading from "../others/Loading";
import {Typeahead} from "react-bootstrap-typeahead";

const Official = () => {
    const token = Cookies.get('token');
    const [formData, setFormData] = useState({});
    const [isModifying, setModifying] = useState(false);

    const [pageNumberOfficial, setPageNumberOfficial] = useState(0);
    const [pageSizeOfficial, setPageSizeOfficial] = useState(10);
    const [totalOfficial, setTotalOfficial] = useState(0);
    const [searchTermOfficial, setSearchTermOfficial] = useState('');
    const [isLoadingOfficial, setLoadingOfficial] = useState(true)
    const [official, setOfficial] = useState([])
    const [showAddModalOfficial, setAddModalOfficial] = useState(false);
    const [showAddConfrimModalOfficial, setAddConfirmModalOfficial] = useState(false);
    const pageCountOfficial = Math.ceil(totalOfficial / pageSizeOfficial);
    const [deleteOfficialId, setDeleteOfficialId] = useState(null);
    const [deleteOfficialName, setDeleteOfficialName] = useState(null);
    const [showDeleteModalOfficial, setShowDeleteModalOfficial] = useState(false);

    const [selectedOfficialId, setSelectedOfficialId] = useState(null);
    const [confirmAction, setConfirmAction] = useState(null);
    const [showConfirmModalChangeStatus, setShowConfirmModalChangeStatus] = useState(false);

    const [persons, setPersons] = useState([]);
    const [selectedPerson, setSelectedPerson] = useState(null);
    const [isLoadingPerson, setIsLoadingPerson] = useState(false);
    const [pagePerson, setPagePerson] = useState(1);
    const [totalPerson, setTotalPerson] = useState(0);
    const [searchPerson, setSearchPerson] = useState("");


    // Debounced input handler
    const handleInputChangePerson = ((query) => {
        setSearchPerson(query);
        setPagePerson(1); // Reset to first page
        fetchPersons(1, query);
    });

    useEffect(() => {
        fetchPersons(1)
    }, [token]);


    const handlePersonChange = (selected) => {
        if (selected.length > 0) {
            setSelectedPerson(selected[0]);
        } else {
            setSelectedPerson(null);
        }
    };

      const fetchPersons = (page = 1, search = "") => {
        setIsLoadingPerson(true);
        axios
            .get(`${process.env.REACT_APP_API_URL}/persons-typeahead`, {
                headers: {Authorization: `Bearer ${token}`},
                params: {page, page_size: 20, search},
            })
            .then((response) => {
                if (page === 1) {
                    setPersons(response.data.data); // Replace results for the first page
                } else {
                    setPersons((prevPersons) => {
                        const newResults = response.data.data.filter(
                            (newPerson) => !prevPersons.some((person) => person.id === newPerson.id)
                        );
                        return [...prevPersons, ...newResults];
                    });
                }
                setTotalPerson(response.data.total_count); // Update the total number of results
            })
            .catch((error) => {
                console.error("Error fetching persons:", error);
            })
            .finally(() => {
                setIsLoadingPerson(false);
            });
    };


    const [positions, setPositions] = useState([]);
    const [selectedPosition, setSelectedPosition] = useState(null);

    const [addAccount, setAddAccount] = useState("");

    const handleAddAccountChange = (e) => {
        setAddAccount(e.target.value); // Update state based on dropdown selection
    };

    const handlePageChangeOfficial = ({selected}) => {
        setPageNumberOfficial(selected);
    };

    useEffect(() => {
        fetchOfficial();
    }, [token, pageNumberOfficial, pageSizeOfficial, searchTermOfficial]);

    useEffect(() => {
        setPageNumberOfficial(0); // Reset the page number when search term changes
    }, [searchTermOfficial]);

    const fetchOfficial = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/officials?page=${pageNumberOfficial + 1}&page_size=${pageSizeOfficial}&search=${searchTermOfficial}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setOfficial(response.data.data);
                setTotalOfficial(response.data.total); // Ensure total is set correctly
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setLoadingOfficial(false)
            })
    };




    useEffect(() => {
        fetchPositions()
    }, []);

    const fetchPositions = () => {
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/positions-typeahead`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            )
            .then((response) => {
                setPositions(response.data.data);
            });
    };

    const handlePositionChange = (selected) => {
        if (selected.length > 0) {
            setSelectedPosition(selected[0]);
        } else {
            setSelectedPosition(null);
        }
    };


    function confirmAddOfficial(event) {
        event.preventDefault();
        setModifying(true);

        if (!selectedPerson || !selectedPosition) {
            toast.error("Please select a person and a position.");
            setModifying(false);
            return;
        }

        const formData = new FormData(event.target);

        const password = formData.get("password");
        const repeatPassword = formData.get("repeat_password");

        if (addAccount === "YES" && password !== repeatPassword) {
            toast.error("Passwords do not match.");
            setModifying(false);
            return;
        }

        const data = {
            person: `${selectedPerson.lastname}, ${selectedPerson.middlename || ''} ${selectedPerson.firstname} ${selectedPerson.suffix || ''}`.trim(),
            person_id: selectedPerson.id,
            position: selectedPosition.position_name,
            position_id: selectedPosition.id,
            start_date: formData.get("start_date"),
            end_date: formData.get("end_date"),
            add_account: formData.get("add_account"),
            username: formData.get("username") || null,
            password: password || null,
        };

        setFormData(data);
        setAddConfirmModalOfficial(true);
    }

    function handleAddOfficial() {
        axios.post(`${process.env.REACT_APP_API_URL}/add-official`, formData, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                fetchOfficial()
                toast.success(response.data.message)
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setModifying(false)
                setAddModalOfficial(false)
                setSelectedPerson(null)
                setSelectedPosition(null)
            })
    }


    function confirmDeleteOfficial(id, official_name) {
        setDeleteOfficialId(id);
        setDeleteOfficialName(`${official_name}`);
        setShowDeleteModalOfficial(true);
    }

    function handleDeleteOfficial(id) {
        axios.delete(`${process.env.REACT_APP_API_URL}/delete-official/${id}`, {
            method: 'DELETE', headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                fetchOfficial()
                toast.success(response.data.message)
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            });
    }

    const handleConfirmChangeStatus = (id, action) => {
        setSelectedOfficialId(id);
        setConfirmAction(action);
        setShowConfirmModalChangeStatus(true);
    };

    const handleChangeStatus = () => {
        axios.put(`${process.env.REACT_APP_API_URL}/change-official-status/${selectedOfficialId}`, {status: confirmAction}, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                toast.success(response.data.message);
                fetchOfficial();
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setShowConfirmModalChangeStatus(false);
            });
    };


    if (isLoadingOfficial) {
        return (<Loading/>);
    }

    return (
        <>
            <div className="container-fluid">
                <h3 className="text-center text-white bg-dark py-3 rounded mt-3">
                    <i className="fa fa-id-badge me-2"></i>Barangay Officials
                </h3>
                <div className="card shadow border-0">
                <div className="card-header d-flex justify-content-between align-items-center bg-light">
                        <h5 className="text-primary fw-bold mb-0">
                            <i className="fa fa-list me-2"></i>Barangay Official Information
                        </h5>
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => setAddModalOfficial(true)}
                        >
                            <i className="fa fa-plus me-2"></i>Add New Official
                        </button>
                    </div>
                    <div className="card-body">
                        {/* Search and Pagination */}
                        <div className="row mb-4">
                            <div className="col-md-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search Official"
                                    value={searchTermOfficial}
                                    onChange={(e) => setSearchTermOfficial(e.target.value)}
                                />
                            </div>
                            <div className="col-md-2">
                                <select
                                    className="form-select"
                                    value={pageSizeOfficial}
                                    onChange={(e) => {
                                        setPageSizeOfficial(Number(e.target.value));
                                        setPageNumberOfficial(0);
                                    }}
                                >
                                    {[10, 20, 30, 40, 50].map((size) => (
                                        <option key={size} value={size}>
                                            Show {size} per page
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        {/* Table */}
                        <div className="table-responsive">
                            <table className="table table-bordered table-hover align-middle">
                                <thead className="table-primary">
                                <tr>
                                    <th>#</th>
                                    <th>Last Name</th>
                                    <th>Middle Name</th>
                                    <th>First Name</th>
                                    <th>Suffix</th>
                                    <th>Position</th>
                                    <th>Date Start</th>
                                    <th>Date End</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {official.length === 0 ? (
                                    <tr>
                                        <td colSpan="10" className="text-center text-muted">
                                            No results found.
                                        </td>
                                    </tr>
                                ) : (
                                    official.map((data, index) => (
                                        <tr key={data.id}>
                                            <td>{index + 1 + pageNumberOfficial * pageSizeOfficial}</td>
                                            <td>{data.person.lastname}</td>
                                            <td>{data.person.middlename}</td>
                                            <td>{data.person.firstname}</td>
                                            <td>{data.person.suffix}</td>
                                            <td>{data.positions.position_name}</td>
                                            <td>{data.start_date}</td>
                                            <td>{data.end_date}</td>
                                            <td>
                                        <span
                                            className={`badge ${
                                                data.status === "ACTIVE"
                                                    ? "bg-success"
                                                    : "bg-secondary"
                                            }`}
                                        >
                                            {data.status}
                                        </span>
                                            </td>
                                            <td>
                                                <button
                                                    className="btn btn-danger btn-sm me-2"
                                                    hidden
                                                    onClick={() =>
                                                        confirmDeleteOfficial(
                                                            data.id,
                                                            `${data.person.lastname}, ${data.person.middlename} ${data.person.firstname} ${data.person.suffix}`
                                                        )
                                                    }
                                                >
                                                    <i className="fa fa-trash"></i>
                                                </button>
                                                <button
                                                    className={`btn btn-sm ${
                                                        data.status === "ACTIVE"
                                                            ? "btn-dark"
                                                            : "btn-success"
                                                    }`}
                                                    onClick={() =>
                                                        handleConfirmChangeStatus(
                                                            data.id,
                                                            data.status === "ACTIVE"
                                                                ? "INACTIVE"
                                                                : "ACTIVE"
                                                        )
                                                    }
                                                >
                                                    <i
                                                        className={`fa ${
                                                            data.status === "ACTIVE"
                                                                ? "fa-toggle-off"
                                                                : "fa-toggle-on"
                                                        }`}
                                                    ></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                                </tbody>
                            </table>
                        </div>

                        {/* Pagination */}
                        <ReactPaginate
                            pageCount={pageCountOfficial}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChangeOfficial}
                            containerClassName="pagination justify-content-center mt-4"
                            activeClassName="active"
                            pageLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            breakLinkClassName="page-link"
                            pageClassName="page-item"
                            previousClassName="page-item"
                            nextClassName="page-item"
                            breakClassName="page-item"
                            disabledClassName="disabled"
                        />
                    </div>
                </div>
            </div>


            <Modal
                size="lg"
                show={showAddModalOfficial}
                onHide={() => setAddModalOfficial(false)}
                aria-labelledby="modal-add-official"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="modal-add-official" className="text-primary">
                        <i className="fa fa-user-plus me-2"></i>Add New Official
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={confirmAddOfficial}>
                        <Row className="mb-3">
                            <Col>
                                <label htmlFor="person" className="form-label fw-bold">
                                    Person <span className="text-danger">*</span>
                                </label>
                                <Typeahead
                                    id="person"
                                    labelKey={(option) =>
                                        `${option.lastname}, ${option.middlename || ""} ${option.firstname} ${option.suffix || ""}`.trim()
                                    }
                                    options={persons}
                                    paginate
                                    maxResults={10}
                                    isLoading={isLoadingPerson}
                                    onInputChange={handleInputChangePerson}
                                    onPaginate={() => {
                                        if (persons.length < totalPerson) {
                                            fetchPersons(pagePerson + 1, searchPerson);
                                            setPagePerson((prev) => prev + 1);
                                        }
                                    }}
                                    placeholder="Search for a person..."
                                    selected={selectedPerson ? [selectedPerson] : []}
                                    onChange={handlePersonChange}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <label htmlFor="position" className="form-label fw-bold">
                                    Position <span className="text-danger">*</span>
                                </label>
                                <Typeahead
                                    id="position"
                                    labelKey="position_name"
                                    options={positions}
                                    placeholder="Choose a position..."
                                    onChange={handlePositionChange}
                                    selected={selectedPosition ? [selectedPosition] : []}
                                    disabled={!selectedPerson}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <label htmlFor="start_date" className="form-label fw-bold">
                                    Date Start <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="start_date"
                                    id="start_date"
                                    required
                                />
                            </Col>
                            <Col>
                                <label htmlFor="end_date" className="form-label fw-bold">
                                    Date End
                                </label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="end_date"
                                    id="end_date"
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <label htmlFor="add_account" className="form-label fw-bold">
                                    Add User Account? <span className="text-danger">*</span>
                                </label>
                                <select
                                    className="form-select"
                                    name="add_account"
                                    id="add_account"
                                    value={addAccount}
                                    onChange={handleAddAccountChange}
                                    required
                                >
                                    <option value="" disabled>
                                        Select
                                    </option>
                                    <option value="YES">Yes</option>
                                    <option value="NO">No</option>
                                </select>
                            </Col>
                        </Row>

                        {addAccount === "YES" && (
                            <Row className="mb-3">
                                <Col>
                                    <label htmlFor="username" className="form-label fw-bold">
                                        Username <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="username"
                                        id="username"
                                        required
                                    />
                                </Col>
                                <Col>
                                    <label htmlFor="password" className="form-label fw-bold">
                                        Password <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        name="password"
                                        id="password"
                                        required
                                    />
                                </Col>
                                <Col>
                                    <label htmlFor="repeat_password" className="form-label fw-bold">
                                        Repeat Password <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        name="repeat_password"
                                        id="repeat_password"
                                        required
                                    />
                                </Col>
                            </Row>
                        )}

                        <div className="d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary" disabled={isModifying}>
                                {isModifying ? <i className="fa fa-spinner fa-spin me-1"></i> :
                                    <i className="fa fa-plus me-1"></i>}
                                Add Official
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>


            <Modal
                show={showAddConfrimModalOfficial}
                onHide={() => setAddConfirmModalOfficial(false)}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-primary">
                        <i className="fa fa-info-circle me-2"></i>Confirm Official Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="mb-3">
                        <Col>
                            <h6 className="text-muted">Official Information</h6>
                            <p>
                                <strong>Person:</strong> {formData.person || "N/A"}
                            </p>
                            <p>
                                <strong>Position:</strong> {formData.position || "N/A"}
                            </p>
                            <p>
                                <strong>Date Start:</strong> {formData.start_date || "N/A"}
                            </p>
                            <p>
                                <strong>Date End:</strong> {formData.end_date || "N/A"}
                            </p>
                        </Col>
                        {addAccount === "YES" && (
                            <Col>
                                <h6 className="text-muted">Account Details</h6>
                                <p>
                                    <strong>Username:</strong> {formData.username || "N/A"}
                                </p>
                                <p>
                                    <strong>Password:</strong> {formData.password || "N/A"}
                                </p>
                            </Col>
                        )}
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setAddConfirmModalOfficial(false);
                            setModifying(false);
                        }}
                    >
                        <i className="fa fa-times me-1"></i>Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            setAddConfirmModalOfficial(false);
                            handleAddOfficial();
                        }}
                    >
                        <i className="fa fa-check me-1"></i>Confirm
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal
                show={showDeleteModalOfficial}
                onHide={() => setShowDeleteModalOfficial(false)}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-danger">
                        <i className="fa fa-trash me-2"></i>Delete Official
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-center fs-5">
                        Are you sure you want to delete the official:
                        <span className="fw-bold text-danger"> {deleteOfficialName || "N/A"}?</span>
                    </p>
                    <p className="text-center text-muted">
                        This action cannot be undone.
                    </p>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button
                        variant="secondary"
                        onClick={() => setShowDeleteModalOfficial(false)}
                        className="px-4"
                    >
                        <i className="fa fa-times me-1"></i>Cancel
                    </Button>
                    <Button
                        variant="danger"
                        onClick={() => {
                            handleDeleteOfficial(deleteOfficialId);
                            setShowDeleteModalOfficial(false);
                        }}
                        className="px-4"
                    >
                        <i className="fa fa-trash me-1"></i>Delete
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal
                show={showConfirmModalChangeStatus}
                onHide={() => setShowConfirmModalChangeStatus(false)}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-warning">
                        <i className="fa fa-exclamation-circle me-2"></i>Confirm Status Change
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-center fs-5">
                        Are you sure you want to change this official's status to{" "}
                        <span
                            className={`fw-bold ${
                                confirmAction === "ACTIVE" ? "text-success" : "text-danger"
                            }`}
                        >
                {confirmAction === "ACTIVE" ? "ACTIVE" : "INACTIVE"}
            </span>
                        ?
                    </p>
                    <p className="text-center text-muted">
                        This action will update the official's current status.
                    </p>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button
                        variant="secondary"
                        onClick={() => setShowConfirmModalChangeStatus(false)}
                        className="px-4"
                    >
                        <i className="fa fa-times me-1"></i>Cancel
                    </Button>
                    <Button
                        variant={confirmAction === "ACTIVE" ? "success" : "danger"}
                        onClick={handleChangeStatus}
                        className="px-4"
                    >
                        <i
                            className={`fa ${
                                confirmAction === "ACTIVE" ? "fa-toggle-on" : "fa-toggle-off"
                            } me-1`}
                        ></i>
                        {confirmAction === "ACTIVE" ? "Activate" : "Deactivate"}
                    </Button>
                </Modal.Footer>
            </Modal>


        </>
    )
}

export default Official