import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/css/loader.css'
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/mainlayout.min.css'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider,} from "react-router-dom";
import "./components/pages/others/domManipulation"
import {ToastContainer} from "react-toastify";
import NotFound from "./components/pages/others/NotFound";
import Body from "./components/pages/layout/Body";
import Login from "./components/pages/main/Login";
import Dashboard from "./components/pages/main/Dashboard";
import Settings from "./components/pages/main/Settings";
import Person from "./components/pages/main/Person";
import Official from "./components/pages/main/Official";
import Events from "./components/pages/main/Events";
import Attendees from "./components/pages/main/eventPages/Attendees";
import Household from "./components/pages/main/Household";
import HouseholdMembers from "./components/pages/main/householdMembersPages/HouseholdMembers";
import EventReports from "./components/pages/main/reports/EventReports";

const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route path='/' element={<Login/>}/>
            <Route path='/portal/dashboard'
                   element={<Body element={<Dashboard/>}/>}/>
            <Route path='/portal/person'
                   element={<Body element={<Person/>}/>}/>
            <Route path='/portal/household'
                   element={<Body element={<Household/>}/>}/>
            <Route path='/portal/household/members/:household_id/:person_name/:purok_name'
                   element={<Body element={<HouseholdMembers/>}/>}/>
            <Route path='/portal/barangay-official'
                   element={<Body element={<Official/>}/>}/>
            <Route path='/portal/events'
                   element={<Body element={<Events/>}/>}/>
               <Route path='/portal/events/reports'
                   element={<Body element={<EventReports/>}/>}/>
            <Route path='/portal/event/attendees/:event_id/:event_name'
                   element={<Body element={<Attendees/>}/>}/>
            <Route path='/portal/settings'
                   element={<Body element={<Settings/>}/>}/>
            <Route path="*" element={<NotFound/>}/>
        </>
    )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        />
        <RouterProvider router={router}/>
    </React.StrictMode>
);
