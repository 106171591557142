import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Loading from "../others/Loading";
import Cookies from 'js-cookie';

const Login = () => {
    const navigate = useNavigate();
    const token = Cookies.get('token');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL}/auth/initializer`)
            .then(response => {})
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    function handleLogin() {
        axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, { username, password }, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                const token = response.data.access_token;
                Cookies.set('token', token, { expires: 1 });
                toast.success('Logged in Successfully');
                navigate('/portal/dashboard');
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            });
    }

    useEffect(() => {
        const authenticated = Boolean(token);
        if (authenticated) {
            navigate('/portal/dashboard');
        } else {
            setLoading(false);
        }
    }, [navigate, token]);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div className="container-fluid">
            <div className="row justify-content-center align-items-center min-vh-100">
                <div className="col-md-6 col-lg-4">
                    <div className="card shadow-lg border-0 rounded-lg">
                        <div className="card-body">
                            <div className="text-center mb-4">
                                <h4 className="text-dark">Barangay System</h4>
                            </div>

                            <div className="mb-3">
                                <input
                                    className="form-control"
                                    placeholder="Username"
                                    type="text"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    required
                                />
                            </div>

                            <div className="mb-3">
                                <input
                                    className="form-control"
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div>

                            <button
                                onClick={handleLogin}
                                className="btn btn-primary d-block w-100 py-2"
                            >
                                Login
                            </button>
                        </div>
                    </div>

                    <footer className="footer mt-4">
                        <p className="text-center my-3 text-muted">
                            &copy; {new Date().getFullYear()} Barangay System. All Rights Reserved.
                        </p>
                    </footer>
                </div>
            </div>
        </div>
    );
}

export default Login;
