import React, {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import {Typeahead} from 'react-bootstrap-typeahead';
import {Button, Card, Col, Row, Spinner, Table} from 'react-bootstrap';
import Cookies from 'js-cookie';
import {useReactToPrint} from "react-to-print";

const EventReport = () => {
    const token = Cookies.get('token');
    const [puroks, setPuroks] = useState([]);
    const [events, setEvents] = useState([]);
    const [selectedEvents, setSelectedEvents] = useState([]);
    const [eventAttendees, setEventAttendees] = useState([]);  // This holds the event attendee data
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingEvents, setIsFetchingEvents] = useState(false);

    const contentRef = useRef(null);

    const reactToPrintFn = useReactToPrint({contentRef});


    useEffect(() => {
        fetchEvents();
    }, []);

    const fetchEvents = async () => {
        setIsFetchingEvents(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/events-typeahead`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            setEvents(response.data.data);
        } catch (error) {
            console.error('Error fetching events:', error);
        } finally {
            setIsFetchingEvents(false);
        }
    };

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const eventIds = selectedEvents.map((event) => event.id);
            const params = new URLSearchParams();
            eventIds.forEach((id) => params.append('event_ids', id));

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/event-reports?${params.toString()}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            console.log('Fetched Data:', response.data);
            setPuroks(response.data.puroks);
            setEventAttendees(response.data.events);  // Store event attendees data
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    // Directly use the eventAttendees data to match attendees for each Purok and Event
    const getAttendeesForPurok = (eventId, purokId) => {
        // Find the event in eventAttendees based on eventId
        const event = eventAttendees.find(event => event.event_id === eventId);

        if (event && event.attendees_by_purok) {
            // Check if the purokId exists in attendees_by_purok and return the value
            return event.attendees_by_purok[String(purokId)] || 0;
        }
        return 0;  // Return 0 if no attendees found or event is missing
    };

    return (
        <div className="container-fluid">
            <h3 className="text-center text-white bg-dark py-3 rounded mt-3">
                <i className="fa fa-file-alt me-2"></i>Event Reports
            </h3>
            <div className="text-center mt-4">
                <Button variant="warning" onClick={() => reactToPrintFn()}>
                    <i className="fa fa-print me-2"></i>Print Report
                </Button>
            </div>
            {/* Card Wrapper */}
            <Card className="shadow-sm rounded mt-4">
                <Card.Header className="bg-gray text-primary">
                    <h5 className="mb-0">
                        <i className="fa fa-calendar me-2"></i>Select Events & Generate Report
                    </h5>
                </Card.Header>
                <Card.Body>
                    <Row className="mb-1">
                        <Col md={10}>
                            <Typeahead
                                id="event-selector"
                                labelKey="event_name"
                                multiple
                                options={events}
                                placeholder={isFetchingEvents ? 'Loading events...' : 'Select events...'}
                                onChange={setSelectedEvents}
                                isLoading={isFetchingEvents}
                                className="w-100 form-control-lg custom-typeahead"
                                renderMenuItemChildren={(option) => (
                                    <div className="d-flex align-items-center">
                                        <i className="fa fa-calendar me-2"></i>
                                        <span>{option.event_name}</span>
                                    </div>
                                )}
                            />
                        </Col>
                        <Col md={2} className="text-end">
                            <Button variant="primary" onClick={fetchData} disabled={isLoading || isFetchingEvents}
                                    className="btn-lg">
                                {isLoading ? (
                                    <>
                                        <Spinner animation="border" size="sm" className="me-2"/>
                                        Generating Report...
                                    </>
                                ) : (
                                    <>
                                        <i className="fa fa-file-alt me-2"></i>
                                        Generate Report
                                    </>
                                )}
                            </Button>
                        </Col>
                    </Row>

                    {/* Table */}
                    <div className="table-responsive">
                        <Table bordered hover responsive className="text-center shadow-sm rounded">
                            <thead className="table-primary">
                            <tr>
                                <th>Purok</th>
                                <th>Households</th>
                                <th>Beneficiaries</th>
                                {selectedEvents.map((event) => (
                                    <th key={event.id}>{event.event_name}</th>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            {puroks.map((purok) => (
                                <tr key={purok.purok_id}>
                                    <td>{purok.purok_name}</td>
                                    <td>{purok.total_households}</td>
                                    <td>{purok.total_beneficiaries}</td>
                                    {selectedEvents.map((event) => (
                                        <td key={event.id}>
                                            {getAttendeesForPurok(event.id, purok.purok_id)}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                            <tr className="fw-bold table-secondary">
                                <td>TOTAL</td>
                                <td>{puroks.reduce((sum, p) => sum + p.total_households, 0)}</td>
                                <td>{puroks.reduce((sum, p) => sum + p.total_beneficiaries, 0)}</td>
                                {selectedEvents.map((event) => (
                                    <td key={event.id}>
                                        {puroks.reduce((sum, purok) => {
                                            return sum + getAttendeesForPurok(event.id, purok.purok_id);
                                        }, 0)}
                                    </td>
                                ))}
                            </tr>
                            </tbody>
                        </Table>
                    </div>
                </Card.Body>
            </Card>

            <div ref={contentRef} className="printable-section p-4">
                <h3 className="text-center text-black">Event Reports</h3>
                <Table bordered hover responsive className="text-center">
                    <thead>
                    <tr>
                        <th>PUROK</th>
                        <th>HOUSEHOLDS</th>
                        <th>BENEFICIARIES</th>
                        {selectedEvents.map((event) => (
                            <th key={event.id}>{event.event_name}</th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {puroks.map((purok) => (
                        <tr key={purok.purok_id}>
                            <td>{purok.purok_name}</td>
                            <td>{purok.total_households}</td>
                            <td>{purok.total_beneficiaries}</td>
                            {selectedEvents.map((event) => (
                                <td key={event.id}>
                                    {getAttendeesForPurok(event.id, purok.purok_id)}
                                </td>
                            ))}
                        </tr>
                    ))}
                    <tr className="fw-bold">
                        <td>TOTAL</td>
                        <td>{puroks.reduce((sum, p) => sum + p.total_households, 0)}</td>
                        <td>{puroks.reduce((sum, p) => sum + p.total_beneficiaries, 0)}</td>
                        {selectedEvents.map((event) => (
                            <td key={event.id}>
                                {puroks.reduce((sum, purok) => {
                                    return sum + getAttendeesForPurok(event.id, purok.purok_id);
                                }, 0)}
                            </td>
                        ))}
                    </tr>
                    </tbody>
                </Table>
            </div>
        </div>


    );
};

export default EventReport;
