import {Link, useLocation, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import Cookies from "js-cookie";

const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleLogout = () => {
        if (Cookies.get('token')) {
            Cookies.remove('token'); // Remove token from cookies
            toast.success('Logged out successfully'); // Success message
        } else {
            toast.info('You were not logged in.'); // Inform if no token exists
        }
        navigate("/login"); // Redirect to login
    };
    const isActiveRoute = (route) => {
        return location.pathname === route;
    };

    function getUserRole(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.account_type;
    }

    // Get the token from localStorage
    const token = Cookies.get('token');
    const userRole = getUserRole(token);

    return (
        <>
            <nav className="navbar navbar-dark align-items-start sidebar sidebar-dark accordion bg-gradient-dark p-0">
                <div className="container-fluid d-flex flex-column p-0">
                    <Link className="navbar-brand d-flex justify-content-center align-items-center sidebar-brand m-0"
                          to='/portal/dashboard'>
                        <div className="sidebar-brand-text mx-2"><span>Barangay System</span>
                        </div>
                    </Link>
                    <hr className="sidebar-divider my-0"/>
                    <ul className="navbar-nav text-light" id="accordionSidebar">

                        {/* Dashboard link is visible to all users */}
                        <li className='nav-item'>
                            <Link to='/portal/dashboard'
                                  className={`nav-link${isActiveRoute('/portal/dashboard') ? ' active' : ''}`}>
                                <i className="fas fa-tachometer-alt"></i><span>Dashboard</span>
                            </Link>
                        </li>

                        <hr className='sidebar-divider'/>

                        <li className='nav-item'>
                            <Link to='/portal/person'
                                  className={`nav-link${isActiveRoute('/portal/person') ? ' active' : ''}`}>
                                <i className="fas fa-users"></i><span>Person</span>
                            </Link>
                        </li>

                        <hr className='sidebar-divider'/>

                        <li className='nav-item'>
                            <Link to='/portal/household'
                                  className={`nav-link${isActiveRoute('/portal/household') ? ' active' : ''}`}>
                                <i className="fas fa-home"></i><span>Household</span>
                            </Link>
                        </li>

                        <hr className='sidebar-divider'/>

                        <li className='nav-item'>
                            <Link to='/portal/barangay-official'
                                  className={`nav-link${isActiveRoute('/portal/barangay-official') ? ' active' : ''}`}>
                                <i className="fas fa-id-badge"></i><span>Officers</span>
                            </Link>
                        </li>

                        <hr className='sidebar-divider'/>

                        <li className='nav-item'>
                            <Link to='/portal/events'
                                  className={`nav-link${isActiveRoute('/portal/events') ? ' active' : ''}`}>
                                <i className="fas fa-calendar"></i><span>Events</span>
                            </Link>
                        </li>

                        <hr className='sidebar-divider'/>


                        <li className='nav-item'>
                            <Link to='/portal/settings'
                                  className={`nav-link${isActiveRoute('/portal/settings') ? ' active' : ''}`}>
                                <i className="fas fa-wrench"></i><span>Settings</span>
                            </Link>
                        </li>

                        <hr className='sidebar-divider'/>


                        {/* Logout */}
                        <li className="nav-item">
                            <Link className="nav-link" to='/' onClick={handleLogout}>
                                <i className="fas fa-sign-out-alt"></i><span>Logout</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    );
}

export default Header;
