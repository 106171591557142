import Cookies from "js-cookie";
import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {toast} from "react-toastify";
import Loading from "../../others/Loading";
import ReactPaginate from "react-paginate";
import {Button, Col, Modal, Row} from "react-bootstrap";
import {Typeahead} from "react-bootstrap-typeahead";
import {useReactToPrint} from "react-to-print";

const Attendees = () => {
    const {event_id, event_name} = useParams();
    const token = Cookies.get('token');
    const [formData, setFormData] = useState({});
    const [isModifying, setModifying] = useState(false);
    const navigate = useNavigate();

    const [allAttendees, setAllAttendees] = useState([]);
    const [isFetchingAll, setIsFetchingAll] = useState(true);

    const contentRef = useRef(null);

    const reactToPrintFn = useReactToPrint({contentRef});

    // Fetch event details and all attendees on component mount
    useEffect(() => {
        fetchAllAttendees();
    }, [event_id, token]);

    const fetchAllAttendees = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/all-attendees/${event_id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setAllAttendees(response.data.data);
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setIsFetchingAll(false)
            })
    };


    const [pageNumberAttendees, setPageNumberAttendees] = useState(0);
    const [pageSizeAttendees, setPageSizeAttendees] = useState(10);
    const [totalAttendees, setTotalAttendees] = useState(0);
    const [searchTermAttendees, setSearchTermAttendees] = useState('');
    const [isLoadingAttendees, setLoadingAttendees] = useState(true)
    const [attendees, setAttendees] = useState([])
    const [showAddModalAttendees, setAddModalAttendees] = useState(false);
    const [showAddConfrimModalAttendees, setAddConfirmModalAttendees] = useState(false);
    const pageCountAttendees = Math.ceil(totalAttendees / pageSizeAttendees);
    const [deleteAttendeesId, setDeleteAttendeesId] = useState(null);
    const [deleteAttendeesName, setDeleteAttendeesName] = useState(null);
    const [showDeleteModalAttendees, setShowDeleteModalAttendees] = useState(false);

    const [showEditModalAttendees, setShowEditModalAttendees] = useState(false);
    const [showEditConfrimModalAttendees, setEditConfirmModalAttendees] = useState(false);

    const [persons, setPersons] = useState([]);
    const [selectedPerson, setSelectedPerson] = useState(null);
    const [isLoadingPerson, setIsLoadingPerson] = useState(false);
    const [pagePerson, setPagePerson] = useState(1);
    const [totalPerson, setTotalPerson] = useState(0);
    const [searchPerson, setSearchPerson] = useState("");

    const [isLoadingEvent, setLoadingEvent] = useState(true)
    const [event, setEvent] = useState(null)


    // Debounced input handler
    const handleInputChangePerson = ((query) => {
        setSearchPerson(query);
        setPagePerson(1); // Reset to first page
        fetchPersons(1, query);
    });

    useEffect(() => {
        fetchPersons(1)
    }, [token]);

    const fetchPersons = (page = 1, search = "") => {
        setIsLoadingPerson(true);
        axios
            .get(`${process.env.REACT_APP_API_URL}/persons-typeahead`, {
                headers: {Authorization: `Bearer ${token}`},
                params: {page, page_size: 20, search},
            })
            .then((response) => {
                if (page === 1) {
                    setPersons(response.data.data); // Replace results for the first page
                } else {
                    setPersons((prevPersons) => {
                        const newResults = response.data.data.filter(
                            (newPerson) => !prevPersons.some((person) => person.id === newPerson.id)
                        );
                        return [...prevPersons, ...newResults];
                    });
                }
                setTotalPerson(response.data.total_count); // Update the total number of results
            })
            .catch((error) => {
                console.error("Error fetching persons:", error);
            })
            .finally(() => {
                setIsLoadingPerson(false);
            });
    };

    const handlePersonChange = (selected) => {
        if (selected.length > 0) {
            setSelectedPerson(selected[0]);
        } else {
            setSelectedPerson(null);
        }
    };

    const handlePageChangeAttendees = ({selected}) => {
        setPageNumberAttendees(selected);
    };

    useEffect(() => {
        fetchAttendees();
    }, [token, pageNumberAttendees, pageSizeAttendees, searchTermAttendees]);

    useEffect(() => {
        setPageNumberAttendees(0); // Reset the page number when search term changes
    }, [searchTermAttendees]);

    const fetchAttendees = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/attendees/${event_id}?page=${pageNumberAttendees + 1}&page_size=${pageSizeAttendees}&search=${searchTermAttendees}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setAttendees(response.data.data);
                setTotalAttendees(response.data.total); // Ensure total is set correctly
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setLoadingAttendees(false)
            })
    };

    useEffect(() => {
        fetchEvent();
    }, [token]);

    const fetchEvent = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/event/${event_id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setEvent(response.data.data);
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setLoadingEvent(false)
            })
    };

    function confirmAddAttendees(event) {
        event.preventDefault();
        setModifying(true);

        if (!selectedPerson) {
            toast.error("Please select a person.");
            setModifying(false);
            return;
        }

        const formData = new FormData(event.target);

        const data = {
            person: `${selectedPerson.lastname}, ${selectedPerson.middlename || ''} ${selectedPerson.firstname} ${selectedPerson.suffix || ''}`.trim(),
            person_id: selectedPerson.id,
            event_id: event_id,

        };

        setFormData(data);
        setAddConfirmModalAttendees(true);
    }


    function handleAddAttendees() {
        axios.post(`${process.env.REACT_APP_API_URL}/add-attendees`, formData, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                fetchEvent()
                fetchAttendees()
                toast.success(response.data.message)
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setModifying(false)
                setAddModalAttendees(false)
                setSelectedPerson(null)
            })
    }


    function confirmDeleteAttendees(id, attendees_name) {
        setDeleteAttendeesId(id);
        setDeleteAttendeesName(`${attendees_name}`);
        setShowDeleteModalAttendees(true);
    }

    function handleDeleteAttendees(id) {
        axios.delete(`${process.env.REACT_APP_API_URL}/delete-attendees/${id}`, {
            method: 'DELETE', headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                fetchEvent()
                fetchAttendees()
                toast.success(response.data.message)
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            });
    }

    const convertToLocalTime = (utcDatetime) => {
        const date = new Date(utcDatetime);
        return date.toLocaleString();
    };

    console.log(attendees)

    if (isLoadingAttendees || isLoadingEvent) {
        return (<Loading/>);
    }


    return (
        <>
            <div className="container-fluid">
                <h3 className="text-center text-white bg-dark py-3 rounded mt-2">
                    <i className="fa fa-users me-2"></i>Attendees of Event: {event_name}
                </h3>

                <Row className="mt-4 mb-4">
                    {/* Total Persons Card */}
                    <Col className="d-flex justify-content-center">
                        <div className="card shadow-sm border-0 h-100 custom-card">
                            <div className="card-body d-flex align-items-center custom-card-body">
                                <div className="me-3">
                                    <i className="fas fa-users fa-3x text-primary"></i>
                                </div>
                                <div>
                                    <h6 className="text-uppercase text-dark fw-bold mb-1">Total Attendees</h6>
                                    <h4 className="text-dark fw-bold mb-0">{event.total_attendees}</h4>
                                </div>
                            </div>
                        </div>

                    </Col>
                    <Col className="d-flex justify-content-center">
                        <div className="card shadow-sm border-0 h-100 custom-card">
                            <div className="card-body d-flex align-items-center custom-card-body">
                                <div className="me-3">
                                    <i className="fas fa-home fa-3x text-primary"></i>
                                </div>
                                <div>
                                    <h6 className="text-uppercase text-dark fw-bold mb-1">Total Household Attendees</h6>
                                    <h4 className="text-dark fw-bold mb-0">{event.total_household_attendees}</h4>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

                <div className="text-center mb-3">
                    <button className="btn btn-warning" onClick={() => reactToPrintFn()} disabled={isFetchingAll}>
                        {isFetchingAll ? (
                            <span>
                            <i className="fa fa-spinner fa-spin me-2"></i> Fetching Data...
                        </span>
                        ) : (
                            <span>
                            <i className="fa fa-print me-2"></i> Print Report
                        </span>
                        )}
                    </button>
                </div>

                <div className="card shadow border-0">
                    <div className="card-header d-flex justify-content-between align-items-center bg-light">
                        <h5 className="text-primary fw-bold mb-0">
                            <i className="fa fa-list me-2"></i>Attendees Information
                        </h5>
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => setAddModalAttendees(true)}
                        >
                            <i className="fa fa-plus me-1"></i>Add New Attendees
                        </button>
                    </div>
                    <div className="card-body">
                        {/* Search and Pagination */}
                        <div className="row mb-4">
                            <div className="col-md-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search Attendee / Purok"
                                    value={searchTermAttendees}
                                    onChange={(e) => setSearchTermAttendees(e.target.value)}
                                />
                            </div>
                            <div className="col-md-2">
                                <select
                                    className="form-select"
                                    value={pageSizeAttendees}
                                    onChange={(e) => {
                                        setPageSizeAttendees(Number(e.target.value));
                                        setPageNumberAttendees(0);
                                    }}
                                >
                                    {[10, 20, 30, 40, 50].map((size) => (
                                        <option key={size} value={size}>
                                            Show {size} per page
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        {/* Table */}
                        <div className="table-responsive">
                            <table className="table table-bordered table-hover align-middle">
                                <thead className="table-primary">
                                <tr>
                                    <th>#</th>
                                    <th>Last Name</th>
                                    <th>Middle Name</th>
                                    <th>First Name</th>
                                    <th>Suffix</th>
                                    <th>Purok</th>
                                    <th>Household Number</th>
                                    <th>Household Head</th>
                                    <th>Date & Time</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {attendees.length === 0 ? (
                                    <tr>
                                        <td colSpan="100" className="text-center text-muted">
                                            No results found.
                                        </td>
                                    </tr>
                                ) : (
                                    attendees.map((data, index) => (
                                        <tr key={data.id}>
                                            <td>{index + 1 + pageNumberAttendees * pageSizeAttendees}</td>
                                            <td>{data.person.lastname}</td>
                                            <td>{data.person.middlename}</td>
                                            <td>{data.person.firstname}</td>
                                            <td>{data.person.suffix}</td>
                                            <td>{data.person.purok.purok_name}</td>
                                            <td>{data.person.household_details.id}</td>
                                            <td>{data.person.household_details.lastname}, {data.person.household_details.middlename} {data.person.household_details.firstname} {data.person.household_details.suffix} - {data.person.household_details.purok}</td>
                                            <td>{convertToLocalTime(data.created_date)}</td>
                                            <td>
                                                <button
                                                    className="btn btn-danger btn-sm"
                                                    onClick={() =>
                                                        confirmDeleteAttendees(
                                                            data.id,
                                                            `${data.person.lastname}, ${data.person.middlename} ${data.person.firstname} ${data.person.suffix}`
                                                        )
                                                    }
                                                >
                                                    <i className="fa fa-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                                </tbody>
                            </table>
                        </div>

                        {/* Pagination */}
                        <ReactPaginate
                            pageCount={pageCountAttendees}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChangeAttendees}
                            containerClassName="pagination justify-content-center mt-4"
                            activeClassName="active"
                            pageLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            breakLinkClassName="page-link"
                            pageClassName="page-item"
                            previousClassName="page-item"
                            nextClassName="page-item"
                            breakClassName="page-item"
                            disabledClassName="disabled"
                        />
                    </div>
                </div>

            </div>


            <Modal
                size="lg"
                show={showAddModalAttendees}
                onHide={() => setAddModalAttendees(false)}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-primary">
                        <i className="fa fa-plus-circle me-2"></i>Add New Attendees
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={confirmAddAttendees}>
                        <Row className="mb-3">
                            <Col>
                                <label htmlFor="person" className="form-label fw-bold">
                                    Person <span className="text-danger">*</span>
                                </label>
                                <Typeahead
                                    id="person"
                                    labelKey={(option) =>
                                        `${option.lastname}, ${option.middlename || ""} ${option.firstname} ${option.suffix || ""}`.trim()
                                    }
                                    options={persons}
                                    paginate
                                    maxResults={10}
                                    isLoading={isLoadingPerson}
                                    onInputChange={handleInputChangePerson}
                                    onPaginate={() => {
                                        if (persons.length < totalPerson) {
                                            fetchPersons(pagePerson + 1, searchPerson);
                                            setPagePerson((prev) => prev + 1);
                                        }
                                    }}
                                    placeholder="Search for a person..."
                                    selected={selectedPerson ? [selectedPerson] : []}
                                    onChange={handlePersonChange}
                                />
                            </Col>
                        </Row>
                        <div className="d-flex justify-content-end">
                            <button
                                type="submit"
                                className="btn btn-primary px-4"
                                disabled={isModifying}
                            >
                                {isModifying ? (
                                    <i className="fa fa-spinner fa-spin"></i>
                                ) : (
                                    <i className="fa fa-save me-1"></i>
                                )}
                                Add
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal
                show={showAddConfrimModalAttendees}
                onHide={() => setAddConfirmModalAttendees(false)}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-warning">
                        <i className="fa fa-info-circle me-2"></i>Confirm Attendee Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="fs-5">
                        <strong>Person:</strong> {formData.person || "N/A"}
                    </p>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setAddConfirmModalAttendees(false);
                            setModifying(false);
                        }}
                        className="px-4"
                    >
                        <i className="fa fa-times me-1"></i>Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            setAddConfirmModalAttendees(false);
                            handleAddAttendees();
                        }}
                        className="px-4"
                    >
                        <i className="fa fa-check me-1"></i>Confirm
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal
                show={showDeleteModalAttendees}
                onHide={() => setShowDeleteModalAttendees(false)}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-danger">
                        <i className="fa fa-trash me-2"></i>Delete Attendees
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-center fs-5">
                        Are you sure you want to delete the attendees from:{" "}
                        <span className="fw-bold text-danger">
                {deleteAttendeesName || "N/A"}
            </span>?
                    </p>
                    <p className="text-center text-muted">
                        This action cannot be undone.
                    </p>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button
                        variant="secondary"
                        onClick={() => setShowDeleteModalAttendees(false)}
                        className="px-4"
                    >
                        <i className="fa fa-times me-1"></i>Cancel
                    </Button>
                    <Button
                        variant="danger"
                        onClick={() => {
                            handleDeleteAttendees(deleteAttendeesId);
                            setShowDeleteModalAttendees(false);
                        }}
                        className="px-4"
                    >
                        <i className="fa fa-trash me-1"></i>Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Hidden Printable Section */}
            <div ref={contentRef} className="printable-section p-4">
                <h3 className="text-center mb-4 text-black">Attendees Report</h3>
                <p><strong>Event Name:</strong> {event_name}</p>
                <p><strong>Total Attendees:</strong> {event.total_attendees}</p>
                <p><strong>Total Household Attendees:</strong> {event.total_household_attendees}</p>

                <table className="table table-bordered mt-4">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Last Name</th>
                        <th>Middle Name</th>
                        <th>First Name</th>
                        <th>Suffix</th>
                        <th>Purok</th>
                        <th>Household Number</th>
                        <th>Household Head</th>
                        <th>Date & Time</th>
                    </tr>
                    </thead>
                    <tbody>
                    {allAttendees.map((data, index) => (
                        <tr key={data.id}>
                            <td>{index + 1}</td>
                            <td>{data.person.lastname}</td>
                            <td>{data.person.middlename}</td>
                            <td>{data.person.firstname}</td>
                            <td>{data.person.suffix}</td>
                            <td>{data.person.purok?.purok_name || "N/A"}</td>
                            <td>{data.person.household_details?.id || "N/A"}</td>
                            <td>
                                {data.person.household_details?.lastname},{" "}
                                {data.person.household_details?.middlename}{" "}
                                {data.person.household_details?.firstname}{" "}
                                {data.person.household_details?.suffix} -{" "}
                                {data.person.household_details?.purok || "N/A"}
                            </td>

                            <td>{convertToLocalTime(data.created_date)}</td>

                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default Attendees