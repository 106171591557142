import Cookies from "js-cookie";
import {Badge, Card, Col, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import Loading from "../others/Loading";
import '../../../assets/css/personcard.css'
import {Link} from "react-router-dom";

const Dashboard = () => {
    const token = Cookies.get('token');
    const [isLoadingPersonCount, setLoadingPersonCount] = useState(true)
    const [personCount, setPersonCount] = useState(0);
        const [householdCount, setHouseholdCount] = useState(0);

    const [isLoadingActiveEvents, setLoadingActiveEvents] = useState(true)
    const [activeEvents, setActiveEvents] = useState([])

    const [isLoadingUpcomingEvents, setLoadinggUpcomingEvents] = useState(true)
    const [upcomingEvents, setUpcomingEvents] = useState([])

    useEffect(() => {
        fetchPersonCount();
        fetchActiveEvents()
        fetchUpcomingEvents()
    }, [token]);

    const fetchPersonCount = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/person-count`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setPersonCount(response.data.total_person_count);
                setHouseholdCount(response.data.total_household_count);
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setLoadingPersonCount(false)
            })
    };

    const fetchActiveEvents = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/active-events`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setActiveEvents(response.data.data);
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setLoadingActiveEvents(false)
            })
    };

    const fetchUpcomingEvents = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/upcoming-events`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setUpcomingEvents(response.data.data);
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setLoadinggUpcomingEvents(false)
            })
    };

    if (isLoadingPersonCount || isLoadingUpcomingEvents || isLoadingActiveEvents) {
        return (<Loading/>);
    }


    return (
        <>
            <div className="container-fluid">
                <h3 className="text-center text-white bg-dark py-3 rounded mt-3">
                    <i className="fas fa-tachometer-alt me-2"></i>DASHBOARD
                </h3>

                  <Row className="mt-4 mb-4">
                    {/* Total Persons Card */}
                    <Col className="d-flex justify-content-center">
                        <div className="card shadow-sm border-0 h-100 custom-card">
                            <div className="card-body d-flex align-items-center custom-card-body">
                                <div className="me-3">
                                    <i className="fas fa-users fa-3x text-primary"></i>
                                </div>
                                <div>
                                    <h6 className="text-uppercase text-dark fw-bold mb-1">Total Persons</h6>
                                    <h4 className="text-dark fw-bold mb-0">{personCount}</h4>
                                </div>
                            </div>
                        </div>

                    </Col>
                      <Col className="d-flex justify-content-center">
                      <div className="card shadow-sm border-0 h-100 custom-card">
                            <div className="card-body d-flex align-items-center custom-card-body">
                                <div className="me-3">
                                    <i className="fas fa-home fa-3x text-primary"></i>
                                </div>
                                <div>
                                    <h6 className="text-uppercase text-dark fw-bold mb-1">Total Household</h6>
                                    <h4 className="text-dark fw-bold mb-0">{householdCount}</h4>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>


                <Row className="mt-5">
                    {/* Active Events Column */}
                    <Col md={6}>
                        <h4 className="mb-4 text-primary text-center">
                            <i className="fa fa-bolt me-2"></i>Ongoing Events
                        </h4>
                        {activeEvents && activeEvents.length > 0 ? (
                            activeEvents.map((event) => (
                                <Card key={event.id} className="mb-3 shadow-sm border-primary">
                                    <Card.Body>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <Card.Title className="text-dark fw-bold">{event.event_name}</Card.Title>
                                            <Badge bg="primary">Ongoing Today</Badge>
                                        </div>
                                        <Card.Text className="text-muted">
                                            <div>
                                                <i className="fa fa-calendar me-1"></i>{' '}
                                                <strong>{new Date(event.event_date).toLocaleDateString()}</strong>
                                            </div>
                                            <div className="mt-2">
                                                <i className="fa fa-info-circle me-1"></i>{' '}
                                                <strong>{event.event_type.event_type}</strong>
                                            </div>
                                            <div className="mt-2">
                                                <Link to={`/portal/event/attendees/${event.id}/${event.event_name}`}>
                                                    <i className="fa fa-users me-1"></i>{' '}
                                                    <strong>{event.total_attendees} Attendees</strong>
                                                </Link>
                                                <div className="mt-2">
                                                    <Link
                                                        to={`/portal/event/attendees/${event.id}/${event.event_name}`}>
                                                        <i className="fa fa-home me-1"></i>{' '}
                                                        <strong>{event.total_household_attendees} Household
                                                            Attendees</strong>
                                                    </Link>
                                                </div>
                                            </div>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            ))
                        ) : (
                            <div className="text-center text-muted">
                                <i>No active events at the moment.</i>
                            </div>
                        )}
                    </Col>

                    {/* Upcoming Events Column */}
                    <Col md={6}>
                        <h4 className="mb-4 text-success text-center">
                            <i className="fa fa-clock me-2"></i>Upcoming Events
                        </h4>
                        {upcomingEvents && upcomingEvents.length > 0 ? (
                            upcomingEvents.map((event) => (
                                <Card key={event.id} className="mb-3 shadow-sm border-success">
                                    <Card.Body>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <Card.Title className="text-dark fw-bold">{event.event_name}</Card.Title>
                                            <Badge bg="success">Upcoming</Badge>
                                        </div>
                                        <Card.Text className="text-muted">
                                            <div>
                                                <i className="fa fa-calendar me-1"></i>{' '}
                                                <strong>{new Date(event.event_date).toLocaleDateString()}</strong>
                                            </div>
                                            <div className="mt-2">
                                                <i className="fa fa-info-circle me-1"></i>{' '}
                                                <strong>{event.event_type.event_type}</strong>
                                            </div>
                                            <div className="mt-2">
                                                <Link to={`/portal/event/attendees/${event.id}/${event.event_name}`}>
                                                    <i className="fa fa-users me-1"></i>{' '}
                                                    <strong>{event.total_attendees} attendees</strong>
                                                </Link>
                                            </div>
                                            <div className="mt-2">
                                                <Link
                                                    to={`/portal/event/attendees/${event.id}/${event.event_name}`}>
                                                    <i className="fa fa-home me-1"></i>{' '}
                                                    <strong>{event.total_household_attendees} Household
                                                        Attendees</strong>
                                                </Link>
                                            </div>

                                        </Card.Text>
                                    </Card.Body>
                                </Card>

                            ))
                        ) : (
                            <div className="text-center text-muted">
                                <i>No upcoming events.</i>
                            </div>
                        )}
                    </Col>
                </Row>
            </div>

        </>
    )
}

export default Dashboard