import Cookies from "js-cookie";
import {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import Loading from "../../others/Loading";
import ReactPaginate from "react-paginate";
import {Button, Col, Modal, Row} from "react-bootstrap";
import {Typeahead} from "react-bootstrap-typeahead";
import {useParams} from "react-router-dom";

const HouseholdMembers = () => {
    const {household_id, person_name, purok_name} = useParams();
    const token = Cookies.get('token');
    const [formData, setFormData] = useState([]);
    const [isModifying, setModifying] = useState(false);

    const [pageNumberHouseholdMembers, setPageNumberHouseholdMembers] = useState(0);
    const [pageSizeHouseholdMembers, setPageSizeHouseholdMembers] = useState(10);
    const [totalHouseholdMembers, setTotalHouseholdMembers] = useState(0);
    const [searchTermHouseholdMembers, setSearchTermHouseholdMembers] = useState('');
    const [isLoadingHouseholdMembers, setLoadingHouseholdMembers] = useState(true)
    const [householdMember, setHouseholdMembers] = useState([])
    const [showAddModalHouseholdMembers, setAddModalHouseholdMembers] = useState(false);
    const [showAddConfrimModalHouseholdMembers, setAddConfirmModalHouseholdMembers] = useState(false);
    const pageCountHouseholdMembers = Math.ceil(totalHouseholdMembers / pageSizeHouseholdMembers);
    const [deleteHouseholdMembersId, setDeleteHouseholdMembersId] = useState(null);
    const [deleteHouseholdMembersName, setDeleteHouseholdMembersName] = useState(null);
    const [showDeleteModalHouseholdMembers, setShowDeleteModalHouseholdMembers] = useState(false);

    const [persons, setPersons] = useState([]);
    const [selectedPerson, setSelectedPerson] = useState([]);
    const [isLoadingPerson, setIsLoadingPerson] = useState(false);
    const [pagePerson, setPagePerson] = useState(1);
    const [totalPerson, setTotalPerson] = useState(0);
    const [searchPerson, setSearchPerson] = useState("");

    const handlePageChangeHouseholdMembers = ({selected}) => {
        setPageNumberHouseholdMembers(selected);
    };

    useEffect(() => {
        fetchHouseholdMembers();
    }, [token, pageNumberHouseholdMembers, pageSizeHouseholdMembers, searchTermHouseholdMembers]);

    useEffect(() => {
        setPageNumberHouseholdMembers(0); // Reset the page number when search term changes
    }, [searchTermHouseholdMembers]);

    const fetchHouseholdMembers = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/household-members/${household_id}?page=${pageNumberHouseholdMembers + 1}&page_size=${pageSizeHouseholdMembers}&search=${searchTermHouseholdMembers}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setHouseholdMembers(response.data.data);
                setTotalHouseholdMembers(response.data.total); // Ensure total is set correctly
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setLoadingHouseholdMembers(false)
            })
    };

    function confirmAddHouseholdMembers(event) {
        event.preventDefault();
        setModifying(true);

        if (selectedPerson.length === 0) {
            toast.error("Please select at least one person.");
            setModifying(false);
            return;
        }

        const relationshipInput = document.getElementById('relationship').value.trim();

        if (!relationshipInput) {
            toast.error("Please enter a relationship.");
            setModifying(false);
            return;
        }

        const data = selectedPerson.map(person => ({
            person: `${person.lastname}, ${person.middlename || ''} ${person.firstname} ${person.suffix || ''}`.trim(),
            person_id: person.id,
            relationship: relationshipInput,
        }));

        console.log('Form Data:', data);
        setFormData(data);
        setAddConfirmModalHouseholdMembers(true);
    }

    function handleAddHouseholdMembers() {
        axios.post(`${process.env.REACT_APP_API_URL}/add-household-member/${household_id}`, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                fetchHouseholdMembers();
                toast.success(response.data.message);
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setModifying(false);
                setAddModalHouseholdMembers(false);
                setSelectedPerson([]);
            });
    }


    function confirmDeleteHouseholdMembers(id, householdMember_name) {
        setDeleteHouseholdMembersId(id);
        setDeleteHouseholdMembersName(`${householdMember_name}`);
        setShowDeleteModalHouseholdMembers(true);
    }

    function handleDeleteHouseholdMembers(id) {
        axios.delete(`${process.env.REACT_APP_API_URL}/delete-household-member/${id}`, {
            method: 'DELETE', headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                fetchHouseholdMembers()
                toast.success(response.data.message)
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            });
    }

    // Debounced input handler
    const handleInputChangePerson = ((query) => {
        setSearchPerson(query);
        setPagePerson(1); // Reset to first page
        fetchPersons(1, query);
    });

    useEffect(() => {
        fetchPersons(1)
    }, [token]);


    const handlePersonChange = (selected) => {
        if (selected.length > 0) {
            setSelectedPerson(selected);
        } else {
            setSelectedPerson([]);
        }
    };

    const fetchPersons = (page = 1, search = "") => {
        setIsLoadingPerson(true);
        axios
            .get(`${process.env.REACT_APP_API_URL}/persons-typeahead`, {
                headers: {Authorization: `Bearer ${token}`},
                params: {page, page_size: 20, search},
            })
            .then((response) => {
                if (page === 1) {
                    setPersons(response.data.data); // Replace results for the first page
                } else {
                    setPersons((prevPersons) => {
                        const newResults = response.data.data.filter(
                            (newPerson) => !prevPersons.some((person) => person.id === newPerson.id)
                        );
                        return [...prevPersons, ...newResults];
                    });
                }
                setTotalPerson(response.data.total_count); // Update the total number of results
            })
            .catch((error) => {
                console.error("Error fetching persons:", error);
            })
            .finally(() => {
                setIsLoadingPerson(false);
            });
    };


    if (isLoadingHouseholdMembers) {
        return (<Loading/>);
    }


    return (
        <>
            <div className="container-fluid">
                <h3 className="text-center text-white bg-dark py-3 rounded mt-3">
                    <i className="fa fa-users me-2"></i>Household Members Management of {person_name} - {purok_name}
                </h3>
                <div className="card shadow border-0">
                    <div className="card-header d-flex justify-content-between align-items-center bg-light">
                        <h5 className="text-primary fw-bold mb-0">
                            <i className="fa fa-list me-2"></i>Household Members Information ({person_name} - {purok_name})
                        </h5>
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => setAddModalHouseholdMembers(true)}
                        >
                            <i className="fa fa-plus me-2"></i>Add New HouseholdMembers
                        </button>
                    </div>
                    <div className="card-body">
                        {/* Search and Pagination */}
                        <div className="row mb-4">
                            <div className="col-md-9">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search Household Members"
                                    value={searchTermHouseholdMembers}
                                    onChange={(e) => setSearchTermHouseholdMembers(e.target.value)}
                                />
                            </div>
                            <div className="col-md-3">
                                <select
                                    className="form-select"
                                    value={pageSizeHouseholdMembers}
                                    onChange={(e) => {
                                        setPageSizeHouseholdMembers(Number(e.target.value));
                                        setPageNumberHouseholdMembers(0);
                                    }}
                                >
                                    {[10, 20, 30, 40, 50].map((size) => (
                                        <option key={size} value={size}>
                                            Show {size} per page
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        {/* Table */}
                        <div className="table-responsive">
                            <table className="table table-bordered table-hover align-middle">
                                <thead className="table-primary">
                                <tr>
                                    <th>#</th>
                                    <th>Last Name</th>
                                    <th>Middle Name</th>
                                    <th>First Name</th>
                                    <th>Suffix</th>
                                    <th>Relationship</th>
                                    <th>Gender</th>
                                    <th>Region</th>
                                    <th>Province</th>
                                    <th>City</th>
                                    <th>Barangay</th>
                                    <th>Purok</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {householdMember.length === 0 ? (
                                    <tr>
                                        <td colSpan="15" className="text-center text-muted">
                                            No results found.
                                        </td>
                                    </tr>
                                ) : (
                                    householdMember.map((data, index) => (
                                        <tr key={data.id}>
                                            <td>{index + 1 + pageNumberHouseholdMembers * pageSizeHouseholdMembers}</td>
                                            <td>{data.person.lastname}</td>
                                            <td>{data.person.middlename}</td>
                                            <td>{data.person.firstname}</td>
                                            <td>{data.person.suffix}</td>
                                            <td>{data.relationship}</td>
                                            <td>{data.person.gender}</td>
                                            <td>{data.person.region}</td>
                                            <td>{data.person.province}</td>
                                            <td>{data.person.city}</td>
                                            <td>{data.person.barangay}</td>
                                            <td>{data.person.purok?.purok_name || "N/A"}</td>
                                            <td>
                                                <button
                                                    className="btn btn-danger btn-sm"
                                                    onClick={() =>
                                                        confirmDeleteHouseholdMembers(
                                                            data.id,
                                                            `${data.person.lastname}, ${data.person.middlename} ${data.person.firstname} ${data.person.suffix}`
                                                        )
                                                    }
                                                >
                                                    <i className="fa fa-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                                </tbody>
                            </table>
                        </div>

                        {/* Pagination */}
                        <ReactPaginate
                            pageCount={pageCountHouseholdMembers}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChangeHouseholdMembers}
                            containerClassName="pagination justify-content-center mt-4"
                            activeClassName="active"
                            pageLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            breakLinkClassName="page-link"
                            pageClassName="page-item"
                            previousClassName="page-item"
                            nextClassName="page-item"
                            breakClassName="page-item"
                            disabledClassName="disabled"
                        />
                    </div>
                </div>
            </div>


            <Modal
                size="lg"
                show={showAddModalHouseholdMembers}
                onHide={() => setAddModalHouseholdMembers(false)}
                aria-labelledby="modal-add-householdMember"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="modal-add-householdMember" className="text-primary">
                        <i className="fa fa-user-plus me-2"></i>Add New Household Members
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={confirmAddHouseholdMembers}>
                        <Row className="mb-3">
                            <Col>
                                <label htmlFor="person" className="form-label fw-bold">
                                    Persons <span className="text-danger">*</span>
                                </label>
                                <Typeahead
                                    id="person"
                                    labelKey={(option) =>
                                        `${option.lastname}, ${option.middlename || ""} ${option.firstname} ${option.suffix || ""}`.trim()
                                    }
                                    options={persons}
                                    paginate
                                    maxResults={10}
                                    isLoading={isLoadingPerson}
                                    onInputChange={handleInputChangePerson}
                                    onPaginate={() => {
                                        if (persons.length < totalPerson) {
                                            fetchPersons(pagePerson + 1, searchPerson);
                                            setPagePerson((prev) => prev + 1);
                                        }
                                    }}
                                    placeholder="Search for persons..."
                                    selected={selectedPerson}
                                    onChange={handlePersonChange}
                                    multiple
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <label htmlFor="relationship" className="form-label fw-bold">
                                    Relationship <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="relationship"
                                    id="relationship"
                                    placeholder="Enter relationship"
                                    required
                                />
                            </Col>
                        </Row>
                        <div className="d-flex justify-content-end">
                            <button
                                type="submit"
                                className="btn btn-primary mt-3"
                                disabled={isModifying}
                            >
                                {isModifying ? <i className="fa fa-spinner fa-spin"></i> : "Add"}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>


            <Modal
                show={showAddConfrimModalHouseholdMembers}
                onHide={() => setAddConfirmModalHouseholdMembers(false)}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-primary">
                        <i className="fa fa-info-circle me-2"></i>Confirm Household Member Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="mb-3">
                        <Col>
                            <h6 className="text-muted">Household Member Information</h6>
                            {formData.map((item, index) => (
                                <div key={index}>
                                    <p><strong>Person:</strong> {item.person}</p>
                                    <p><strong>Relationship:</strong> {item.relationship}</p>
                                </div>
                            ))}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setAddConfirmModalHouseholdMembers(false);
                            setModifying(false);
                        }}
                    >
                        <i className="fa fa-times me-1"></i>Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            setAddConfirmModalHouseholdMembers(false);
                            handleAddHouseholdMembers();
                        }}
                    >
                        <i className="fa fa-check me-1"></i>Confirm
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal
                show={showDeleteModalHouseholdMembers}
                onHide={() => setShowDeleteModalHouseholdMembers(false)}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-danger">
                        <i className="fa fa-trash me-2"></i>Delete Household Member
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-center fs-5">
                        Are you sure you want to delete the household Member:
                        <span className="fw-bold text-danger"> {deleteHouseholdMembersName || "N/A"}?</span>
                    </p>
                    <p className="text-center text-muted">
                        This action cannot be undone.
                    </p>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button
                        variant="secondary"
                        onClick={() => setShowDeleteModalHouseholdMembers(false)}
                        className="px-4"
                    >
                        <i className="fa fa-times me-1"></i>Cancel
                    </Button>
                    <Button
                        variant="danger"
                        onClick={() => {
                            handleDeleteHouseholdMembers(deleteHouseholdMembersId);
                            setShowDeleteModalHouseholdMembers(false);
                        }}
                        className="px-4"
                    >
                        <i className="fa fa-trash me-1"></i>Delete
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default HouseholdMembers