import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import Loading from "../others/Loading";
import Header from "./Header";
import Footer from "./Footer";
import UserRoleContext from "../../pages/others/UserRoleContext";
import Cookies from "js-cookie";

const Body = ({element, allowedRoles}) => {
    const token = Cookies.get('token');
    const authenticated = Boolean(token);
    const exp = token ? JSON.parse(atob(token.split(".")[1])).exp : 0;
    const isExpired = exp && exp * 1000 < Date.now();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true)

     function getUserRole(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.account_type;
    }

    const userRole = getUserRole(token);

    useEffect(() => {
        if (isExpired) {
            Cookies.remove("token");
            navigate("/");
            if (isExpired) {
                toast.error("Your session has expired. Please log in again.");
            }
        }
    }, [authenticated, isExpired, navigate]);

    useEffect(() => {
        if (!authenticated) {
            navigate("/");
            toast.error(
                "You cannot access this page."
            );
        } else{
           setLoading(false)
        }

    }, [authenticated, allowedRoles, userRole, navigate]);

     if (isLoading) {
        return (
            <Loading/>
        );
    }

 return (
    <>
            <UserRoleContext.Provider value={userRole}>
                <div id="wrapper">
                    <Header/>
                    <div className="d-flex flex-column" id="content-wrapper">
                        <div id="content">
                            {element}
                        </div>
                        <Footer/>
                    </div>
                </div>
            </UserRoleContext.Provider>
    </>
 )
 }

export default Body;