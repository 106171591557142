import Cookies from "js-cookie";
import {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import Loading from "../others/Loading";
import ReactPaginate from "react-paginate";
import {Button, Col, Modal, Row} from "react-bootstrap";
import './../../../assets/css/profile.css'
import {Typeahead} from "react-bootstrap-typeahead";

const Settings = () => {
    const token = Cookies.get('token');
    const [formData, setFormData] = useState({});
    const [isModifying, setModifying] = useState(false);

    const userId = getUserId(token);

    function getUserId(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.id;
    }

    const [pageNumberPurok, setPageNumberPurok] = useState(0);
    const [pageSizePurok, setPageSizePurok] = useState(10);
    const [totalPurok, setTotalPurok] = useState(0);
    const [isLoadingPurok, setLoadingPurok] = useState(true)
    const [purok, setPurok] = useState([])
    const [showAddModalPurok, setAddModalPurok] = useState(false);
    const [showAddConfrimModalPurok, setAddConfirmModalPurok] = useState(false);
    const pageCountPurok = Math.ceil(totalPurok / pageSizePurok);
    const [deletePurokId, setDeletePurokId] = useState(null);
    const [deletePurokName, setDeletePurokName] = useState(null);
    const [showDeleteModalPurok, setShowDeleteModalPurok] = useState(false);

    const handlePageChangePurok = ({selected}) => {
        setPageNumberPurok(selected);
    };


    const [pageNumberPosition, setPageNumberPosition] = useState(0);
    const [pageSizePosition, setPageSizePosition] = useState(10);
    const [totalPosition, setTotalPosition] = useState(0);
    const [isLoadingPosition, setLoadingPosition] = useState(true)
    const [position, setPosition] = useState([])
    const [showAddModalPosition, setAddModalPosition] = useState(false);
    const [showAddConfrimModalPosition, setAddConfirmModalPosition] = useState(false);
    const pageCountPosition = Math.ceil(totalPosition / pageSizePosition);
    const [deletePositionId, setDeletePositionId] = useState(null);
    const [deletePositionName, setDeletePositionName] = useState(null);
    const [showDeleteModalPosition, setShowDeleteModalPosition] = useState(false);

    const handlePageChangePosition = ({selected}) => {
        setPageNumberPosition(selected);
    };

    const [pageNumberEventType, setPageNumberEventType] = useState(0);
    const [pageSizeEventType, setPageSizeEventType] = useState(10);
    const [totalEventType, setTotalEventType] = useState(0);
    const [isLoadingEventType, setLoadingEventType] = useState(true)
    const [eventType, setEventType] = useState([])
    const [showAddModalEventType, setAddModalEventType] = useState(false);
    const [showAddConfrimModalEventType, setAddConfirmModalEventType] = useState(false);
    const pageCountEventType = Math.ceil(totalEventType / pageSizeEventType);
    const [deleteEventTypeId, setDeleteEventTypeId] = useState(null);
    const [deleteEventTypeName, setDeleteEventTypeName] = useState(null);
    const [showDeleteModalEventType, setShowDeleteModalEventType] = useState(false);

    const handlePageChangeEventType = ({selected}) => {
        setPageNumberEventType(selected);
    };

    const [pageNumberUserAccount, setPageNumberUserAccount] = useState(0);
    const [pageSizeUserAccount, setPageSizeUserAccount] = useState(10);
    const [totalUserAccount, setTotalUserAccount] = useState(0);
    const [isLoadingUserAccount, setLoadingUserAccount] = useState(true)
    const [userAccount, setUserAccount] = useState([])
    const [showAddModalUserAccount, setAddModalUserAccount] = useState(false);
    const [showAddConfrimModalUserAccount, setAddConfirmModalUserAccount] = useState(false);
    const pageCountUserAccount = Math.ceil(totalUserAccount / pageSizeUserAccount);
        const [selectedUserAccountId, setSelectedUserAccountId] = useState(null);
    const [confirmAction, setConfirmAction] = useState(null);
    const [showConfirmModalChangeStatus, setShowConfirmModalChangeStatus] = useState(false);

    const handlePageChangeUserAccount = ({selected}) => {
        setPageNumberUserAccount(selected);
    };

    useEffect(() => {
        fetchUserAccount();
    }, [token, pageNumberUserAccount, pageSizeUserAccount]);
        

    const fetchUserAccount = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/user-accounts?page=${pageNumberUserAccount + 1}&page_size=${pageSizeUserAccount}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setUserAccount(response.data.data);
                setTotalUserAccount(response.data.total); // Ensure total is set correctly
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setLoadingUserAccount(false)
            })
    };

    const [persons, setPersons] = useState([]);
    const [selectedPerson, setSelectedPerson] = useState(null);
    const [isLoadingPerson, setIsLoadingPerson] = useState(false);
    const [pagePerson, setPagePerson] = useState(1);
    const [totalPerson, setTotalPerson] = useState(0);
    const [searchPerson, setSearchPerson] = useState("");


    // Debounced input handler
    const handleInputChangePerson = ((query) => {
        setSearchPerson(query);
        setPagePerson(1); // Reset to first page
        fetchPersons(1, query);
    });


    const handlePersonChange = (selected) => {
        if (selected.length > 0) {
            setSelectedPerson(selected[0]);
        } else {
            setSelectedPerson(null);
        }
    };

    useEffect(() => {
        fetchPersons(1)
    }, [token]);

    const fetchPersons = (page = 1, search = "") => {
        setIsLoadingPerson(true);
        axios
            .get(`${process.env.REACT_APP_API_URL}/persons-typeahead`, {
                headers: {Authorization: `Bearer ${token}`},
                params: {page, page_size: 20, search},
            })
            .then((response) => {
                if (page === 1) {
                    setPersons(response.data.data); // Replace results for the first page
                } else {
                    setPersons((prevPersons) => {
                        const newResults = response.data.data.filter(
                            (newPerson) => !prevPersons.some((person) => person.id === newPerson.id)
                        );
                        return [...prevPersons, ...newResults];
                    });
                }
                setTotalPerson(response.data.total_count); // Update the total number of results
            })
            .catch((error) => {
                console.error("Error fetching persons:", error);
            })
            .finally(() => {
                setIsLoadingPerson(false);
            });
    };

    const [user, setUser] = useState({})
    const [isLoadingUser, setLoadingUser] = useState(true)
    const [showChangePasswordModal, setShowChangePassswordModal] = useState(false);
    const [showConfirmChangePasswordModal, setShowConfirmChangePasswordModal] = useState(false);

    useEffect(() => {
        fetchPurok();
    }, [token, pageNumberPurok, pageSizePurok]);

    const fetchPurok = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/puroks?page=${pageNumberPurok + 1}&page_size=${pageSizePurok}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setPurok(response.data.data);
                setTotalPurok(response.data.total); // Ensure total is set correctly
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setLoadingPurok(false)
            })
    };

    useEffect(() => {
        fetchPosition();
    }, [token, pageNumberPosition, pageSizePosition]);

    const fetchPosition = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/positions?page=${pageNumberPosition + 1}&page_size=${pageSizePosition}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setPosition(response.data.data);
                setTotalPosition(response.data.total); // Ensure total is set correctly
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setLoadingPosition(false)
            })
    };

    useEffect(() => {
        fetchUser();
    }, [token]);

    const fetchUser = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/user/${userId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setUser(response.data.data);
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setLoadingUser(false)
            })
    };

    useEffect(() => {
        fetchEventType();
    }, [token, pageNumberEventType, pageSizeEventType]);

    const fetchEventType = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/event-types?page=${pageNumberEventType + 1}&page_size=${pageSizeEventType}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setEventType(response.data.data);
                setTotalEventType(response.data.total); // Ensure total is set correctly
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setLoadingEventType(false)
            })
    };

    function confirmAddEventType(event) {
        event.preventDefault()
        setModifying(true)

        const formData = new FormData(event.target);

        const data = {
            event_type: formData.get("event_type"),
        };
        setFormData(data);
        setAddConfirmModalEventType(true);
    }

    function handleAddEventType() {
        axios.post(`${process.env.REACT_APP_API_URL}/add-event-type`, formData, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                fetchEventType()
                toast.success(response.data.message)
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setModifying(false)
                setAddModalEventType(false)
            })
    }


    function confirmDeleteEventType(id, event_type) {
        setDeleteEventTypeId(id);
        setDeleteEventTypeName(`${event_type}`);
        setShowDeleteModalEventType(true);
    }

    function handleDeleteEventType(id) {
        axios.delete(`${process.env.REACT_APP_API_URL}/delete-event-type/${id}`, {
            method: 'DELETE', headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                fetchEventType()
                toast.success(response.data.message)
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            });
    }

    function confirmChangePassword(event) {
        event.preventDefault();
        setModifying(true);

        const formData = new FormData(event.target);
        const oldPassword = formData.get("old_password");
        const newPassword = formData.get("new_password");
        const repeatPassword = formData.get("rep_password");

        // Check if new password and repeat password match
        if (newPassword !== repeatPassword) {
            toast.error('New password and repeat password do not match');
            setModifying(false); // Reset modifying state
            return; // Prevent further execution
        }

        const data = {
            old_password: oldPassword,
            new_password: newPassword,
        };
        setFormData(data);
        setShowConfirmChangePasswordModal(true);
    }

    function handleChangePassword() {
        axios.post(`${process.env.REACT_APP_API_URL}/change-password`, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                toast.success(response.data.message)
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setModifying(false)
                setShowChangePassswordModal(false)
            })
    }

    function confirmAddPurok(event) {
        event.preventDefault()
        setModifying(true)

        const formData = new FormData(event.target);

        const data = {
            purok_name: formData.get("purok_name"),
        };
        setFormData(data);
        setAddConfirmModalPurok(true);
    }

    function handleAddPurok() {
        axios.post(`${process.env.REACT_APP_API_URL}/add-purok`, formData, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                fetchPurok()
                toast.success(response.data.message)
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setModifying(false)
                setAddModalPurok(false)
            })
    }

    function confirmDeletePurok(id, purok_name) {
        setDeletePurokId(id);
        setDeletePurokName(`${purok_name}`);
        setShowDeleteModalPurok(true);
    }

    function confirmAddPosition(event) {
        event.preventDefault()
        setModifying(true)

        const formData = new FormData(event.target);

        const data = {
            position_name: formData.get("position_name"),
        };
        setFormData(data);
        setAddConfirmModalPosition(true);
    }

    function handleAddPosition() {
        axios.post(`${process.env.REACT_APP_API_URL}/add-position`, formData, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                fetchPosition()
                toast.success(response.data.message)
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setModifying(false)
                setAddModalPosition(false)
            })
    }

    function handleDeletePurok(id) {
        axios.delete(`${process.env.REACT_APP_API_URL}/delete-purok/${id}`, {
            method: 'DELETE', headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                fetchPurok()
                toast.success(response.data.message)
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            });
    }

    function confirmDeletePosition(id, position_name) {
        setDeletePositionId(id);
        setDeletePositionName(`${position_name}`);
        setShowDeleteModalPosition(true);
    }

    function handleDeletePosition(id) {
        axios.delete(`${process.env.REACT_APP_API_URL}/delete-position/${id}`, {
            method: 'DELETE', headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                fetchPosition()
                toast.success(response.data.message)
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            });
    }

    function confirmAddUserAccount(event) {
        event.preventDefault();
        setModifying(true);

        if (!selectedPerson) {
            toast.error("Please select a person.");
            setModifying(false);
            return;
        }

        const formData = new FormData(event.target);

        const password = formData.get("password");
        const repeatPassword = formData.get("repeat_password");

        if (password !== repeatPassword) {
            toast.error("Passwords do not match.");
            setModifying(false);
            return;
        }

        const data = {
            person: `${selectedPerson.lastname}, ${selectedPerson.middlename || ''} ${selectedPerson.firstname} ${selectedPerson.suffix || ''}`.trim(),
            person_id: selectedPerson.id,
            username: formData.get("username") || null,
            password: password || null,
        };

        setFormData(data);
        setAddConfirmModalUserAccount(true);
    }

    function handleAddUserAccount() {
        axios.post(`${process.env.REACT_APP_API_URL}/add-user-account`, formData, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                fetchUserAccount()
                toast.success(response.data.message)
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setModifying(false)
                setAddModalUserAccount(false)
                setSelectedPerson(null)
            })
    }

    const handleConfirmChangeStatus = (id, action) => {
        setSelectedUserAccountId(id);
        setConfirmAction(action);
        setShowConfirmModalChangeStatus(true);
    };

    const handleChangeStatus = () => {
        axios.put(`${process.env.REACT_APP_API_URL}/change-user-account-status/${selectedUserAccountId}`, {status: confirmAction}, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                toast.success(response.data.message);
                fetchUserAccount();
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setShowConfirmModalChangeStatus(false);
            });
    };

    if (isLoadingPurok || isLoadingPosition || isLoadingUser || isLoadingEventType || isLoadingUserAccount) {
        return (<Loading/>);
    }

    return (
        <>
            <div className="container-fluid">
                {/* Settings Header */}
                <h3 className="text-center text-white bg-dark py-3 rounded mt-2">
                    <i className="fa fa-cogs me-2"></i>Settings
                </h3>

                <div className="card shadow-sm border-0 mb-4 account-info-card">
                    <div className="card-header d-flex justify-content-between align-items-center bg-light">
                        <h5 className="text-primary fw-bold mb-0">
                            <i className="fa fa-user me-2"></i>Account Information
                        </h5>
                    </div>
                    <div className="card-body">
                        {/* Profile Section */}
                        <div className="d-flex justify-content-center align-items-center mb-3">
                            <div className="profile-icon-container">
                                <i className="fa fa-user-circle fa-4x text-primary"></i> {/* Profile icon */}
                            </div>
                            <div className="ms-3">
                                <h5 className="text-dark fw-bold mb-1">{user.username}</h5>
                                <p className="text-muted mb-0">Username</p>
                            </div>
                        </div>

                        {/* Button Section */}
                        <div className="d-flex justify-content-center mt-3">
                            <button
                                className="btn btn-warning btn-sm"
                                onClick={() => setShowChangePassswordModal(true)}
                            >
                                <i className="fa fa-key me-2"></i>Change Password
                            </button>
                        </div>
                    </div>
                </div>


                {/* Purok Information */}
                <div className="card shadow border-0 mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center bg-light">
                        <h5 className="text-primary fw-bold mb-0">
                            <i className="fa fa-map-marker-alt me-2"></i>Purok Information
                        </h5>
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => setAddModalPurok(true)}
                        >
                            <i className="fa fa-plus me-1"></i>Add New Purok
                        </button>
                    </div>
                    <div className="card-body">
                        {/* Search and Pagination */}
                        <div className="row mb-4">
                            <div className="col-md">
                                <select
                                    className="form-select"
                                    value={pageSizePurok}
                                    onChange={(e) => {
                                        setPageSizePurok(Number(e.target.value));
                                        setPageNumberPurok(0);
                                    }}
                                >
                                    {[10, 20, 30, 40, 50].map((size) => (
                                        <option key={size} value={size}>
                                            Show {size} per page
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        {/* Table */}
                        <div className="table-responsive">
                            <table className="table table-bordered table-hover align-middle">
                                <thead className="table-primary">
                                <tr>
                                    <th>#</th>
                                    <th>Purok Name</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {purok.length === 0 ? (
                                    <tr>
                                        <td colSpan="3" className="text-center text-muted">
                                            No results found.
                                        </td>
                                    </tr>
                                ) : (
                                    purok.map((data, index) => (
                                        <tr key={data.id}>
                                            <td>{index + 1 + pageNumberPurok * pageSizePurok}</td>
                                            <td>{data.purok_name}</td>
                                            <td>
                                                <button
                                                    className="btn btn-danger btn-sm"
                                                    onClick={() =>
                                                        confirmDeletePurok(data.id, data.purok_name)
                                                    }
                                                >
                                                    <i className="fa fa-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                                </tbody>
                            </table>
                        </div>

                        {/* Pagination */}
                        <ReactPaginate
                            pageCount={pageCountPurok}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChangePurok}
                            containerClassName="pagination justify-content-center mt-4"
                            activeClassName="active"
                            pageLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            breakLinkClassName="page-link"
                            pageClassName="page-item"
                            previousClassName="page-item"
                            nextClassName="page-item"
                            breakClassName="page-item"
                            disabledClassName="disabled"
                        />
                    </div>
                </div>

                {/* Position Information */}
                <div className="card shadow border-0 mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center bg-light">
                        <h5 className="text-primary fw-bold mb-0">
                            <i className="fa fa-briefcase me-2"></i>Position Information
                        </h5>
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => setAddModalPosition(true)}
                        >
                            <i className="fa fa-plus me-1"></i>Add New Position
                        </button>
                    </div>
                    <div className="card-body">
                        {/* Search and Pagination */}
                        <div className="row mb-4">
                            <div className="col-md">
                                <select
                                    className="form-select"
                                    value={pageSizePosition}
                                    onChange={(e) => {
                                        setPageSizePosition(Number(e.target.value));
                                        setPageNumberPosition(0);
                                    }}
                                >
                                    {[10, 20, 30, 40, 50].map((size) => (
                                        <option key={size} value={size}>
                                            Show {size} per page
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        {/* Table */}
                        <div className="table-responsive">
                            <table className="table table-bordered table-hover align-middle">
                                <thead className="table-primary">
                                <tr>
                                    <th>#</th>
                                    <th>Position Name</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {position.length === 0 ? (
                                    <tr>
                                        <td colSpan="3" className="text-center text-muted">
                                            No results found.
                                        </td>
                                    </tr>
                                ) : (
                                    position.map((data, index) => (
                                        <tr key={data.id}>
                                            <td>{index + 1 + pageNumberPosition * pageSizePosition}</td>
                                            <td>{data.position_name}</td>
                                            <td>
                                                <button
                                                    className="btn btn-danger btn-sm"
                                                    onClick={() =>
                                                        confirmDeletePosition(data.id, data.position_name)
                                                    }
                                                >
                                                    <i className="fa fa-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                                </tbody>
                            </table>
                        </div>

                        {/* Pagination */}
                        <ReactPaginate
                            pageCount={pageCountPosition}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChangePosition}
                            containerClassName="pagination justify-content-center mt-4"
                            activeClassName="active"
                            pageLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            breakLinkClassName="page-link"
                            pageClassName="page-item"
                            previousClassName="page-item"
                            nextClassName="page-item"
                            breakClassName="page-item"
                            disabledClassName="disabled"
                        />
                    </div>
                </div>

                {/* Event Type Information */}
                <div className="card shadow border-0 mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center bg-light">
                        <h5 className="text-primary fw-bold mb-0">
                            <i className="fa fa-calendar me-2"></i>Event Type Information
                        </h5>
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => setAddModalEventType(true)}
                        >
                            <i className="fa fa-plus me-1"></i>Add New Event Type
                        </button>
                    </div>
                    <div className="card-body">
                        {/* Search and Pagination */}
                        <div className="row mb-4">
                            <div className="col-md">
                                <select
                                    className="form-select"
                                    value={pageSizeEventType}
                                    onChange={(e) => {
                                        setPageSizeEventType(Number(e.target.value));
                                        setPageNumberEventType(0);
                                    }}
                                >
                                    {[10, 20, 30, 40, 50].map((size) => (
                                        <option key={size} value={size}>
                                            Show {size} per page
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        {/* Table */}
                        <div className="table-responsive">
                            <table className="table table-bordered table-hover align-middle">
                                <thead className="table-primary">
                                <tr>
                                    <th>#</th>
                                    <th>Event Type</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {eventType.length === 0 ? (
                                    <tr>
                                        <td colSpan="3" className="text-center text-muted">
                                            No results found.
                                        </td>
                                    </tr>
                                ) : (
                                    eventType.map((data, index) => (
                                        <tr key={data.id}>
                                            <td>{index + 1 + pageNumberEventType * pageSizeEventType}</td>
                                            <td>{data.event_type}</td>
                                            <td>
                                                <button
                                                    className="btn btn-danger btn-sm"
                                                    onClick={() =>
                                                        confirmDeleteEventType(data.id, data.event_type)
                                                    }
                                                >
                                                    <i className="fa fa-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                                </tbody>
                            </table>
                        </div>

                        {/* Pagination */}
                        <ReactPaginate
                            pageCount={pageCountEventType}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChangeEventType}
                            containerClassName="pagination justify-content-center mt-4"
                            activeClassName="active"
                            pageLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            breakLinkClassName="page-link"
                            pageClassName="page-item"
                            previousClassName="page-item"
                            nextClassName="page-item"
                            breakClassName="page-item"
                            disabledClassName="disabled"
                        />
                    </div>
                </div>


                {/* User Accounts Information */}
                <div className="card shadow border-0 mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center bg-light">
                        <h5 className="text-primary fw-bold mb-0">
                            <i className="fa fa-users me-2"></i>User Account Information
                        </h5>
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => setAddModalUserAccount(true)}
                        >
                            <i className="fa fa-plus me-1"></i>Add New User Account
                        </button>
                    </div>
                    <div className="card-body">
                        {/* Search and Pagination */}
                        <div className="row mb-4">
                            <div className="col-md">
                                <select
                                    className="form-select"
                                    value={pageSizeUserAccount}
                                    onChange={(e) => {
                                        setPageSizeUserAccount(Number(e.target.value));
                                        setPageNumberUserAccount(0);
                                    }}
                                >
                                    {[10, 20, 30, 40, 50].map((size) => (
                                        <option key={size} value={size}>
                                            Show {size} per page
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        {/* Table */}
                        <div className="table-responsive">
                            <table className="table table-bordered table-hover align-middle">
                                <thead className="table-primary">
                                <tr>
                                    <th>#</th>
                                    <th>Lastname</th>
                                    <th>Middlename</th>
                                    <th>Firstname</th>
                                    <th>Suffix</th>
                                    <th>Username</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {userAccount.length === 0 ? (
                                    <tr>
                                        <td colSpan="100" className="text-center text-muted">
                                            No results found.
                                        </td>
                                    </tr>
                                ) : (
                                    userAccount.map((data, index) => (
                                        <tr key={data.id}>
                                            <td>{index + 1 + pageNumberUserAccount * pageSizeUserAccount}</td>
                                            <td>{data.person.lastname}</td>
                                            <td>{data.person.middlename}</td>
                                            <td>{data.person.firstname}</td>
                                            <td>{data.person.suffix}</td>
                                            <td>{data.username}</td>
                                            <td>
                                        <span
                                            className={`badge ${
                                                data.status === "ACTIVE"
                                                    ? "bg-success"
                                                    : "bg-secondary"
                                            }`}
                                        >
                                            {data.status}
                                        </span>
                                            </td>
                                            <td>
                                                <button
                                                    className={`btn btn-sm me-2 ${
                                                        data.status === "ACTIVE"
                                                            ? "btn-dark"
                                                            : "btn-success"
                                                    }`}
                                                    onClick={() =>
                                                        handleConfirmChangeStatus(
                                                            data.id,
                                                            data.status === "ACTIVE"
                                                                ? "INACTIVE"
                                                                : "ACTIVE"
                                                        )
                                                    }
                                                >
                                                    <i
                                                        className={`fa ${
                                                            data.status === "ACTIVE"
                                                                ? "fa-toggle-off"
                                                                : "fa-toggle-on"
                                                        }`}
                                                    ></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                                </tbody>
                            </table>
                        </div>

                        {/* Pagination */}
                        <ReactPaginate
                            pageCount={pageCountUserAccount}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChangeUserAccount}
                            containerClassName="pagination justify-content-center mt-4"
                            activeClassName="active"
                            pageLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            breakLinkClassName="page-link"
                            pageClassName="page-item"
                            previousClassName="page-item"
                            nextClassName="page-item"
                            breakClassName="page-item"
                            disabledClassName="disabled"
                        />
                    </div>
                </div>

            </div>

            <Modal
                size="lg"
                show={showAddModalUserAccount}
                onHide={() => setAddModalUserAccount(false)}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-primary">
                        <i className="fa fa-calendar me-2"></i>Add New User Account
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={confirmAddUserAccount}>
                        <Row className="mb-3">
                            <Col>
                                <label htmlFor="person" className="form-label fw-bold">
                                    Person <span className="text-danger">*</span>
                                </label>
                                <Typeahead
                                    id="person"
                                    labelKey={(option) =>
                                        `${option.lastname}, ${option.middlename || ""} ${option.firstname} ${option.suffix || ""}`.trim()
                                    }
                                    options={persons}
                                    paginate
                                    maxResults={10}
                                    isLoading={isLoadingPerson}
                                    onInputChange={handleInputChangePerson}
                                    onPaginate={() => {
                                        if (persons.length < totalPerson) {
                                            fetchPersons(pagePerson + 1, searchPerson);
                                            setPagePerson((prev) => prev + 1);
                                        }
                                    }}
                                    placeholder="Search for a person..."
                                    selected={selectedPerson ? [selectedPerson] : []}
                                    onChange={handlePersonChange}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <label htmlFor="username" className="form-label fw-bold">
                                    Username <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="username"
                                    id="username"
                                    required
                                />
                            </Col>
                            <Col>
                                <label htmlFor="password" className="form-label fw-bold">
                                    Password <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="password"
                                    className="form-control"
                                    name="password"
                                    id="password"
                                    required
                                />
                            </Col>
                            <Col>
                                <label htmlFor="repeat_password" className="form-label fw-bold">
                                    Repeat Password <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="password"
                                    className="form-control"
                                    name="repeat_password"
                                    id="repeat_password"
                                    required
                                />
                            </Col>
                        </Row>
                        <div className="d-flex justify-content-end">
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={isModifying}
                            >
                                {isModifying ? (
                                    <i className="fa fa-spinner fa-spin"></i>
                                ) : (
                                    <i className="fa fa-plus me-1"></i>
                                )}
                                Add
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal
                show={showAddConfrimModalUserAccount}
                onHide={() => setAddConfirmModalUserAccount(false)}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-warning">
                        <i className="fa fa-info-circle me-2"></i>Confirm User Account Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="fs-5">
                        <strong>Person:</strong> {formData.person || "N/A"}
                    </p>
                    <p className="fs-5">
                        <strong>Username:</strong> {formData.username || "N/A"}
                    </p>
                    <p>
                        <strong>Password:</strong> {formData.password || "N/A"}
                    </p>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setAddConfirmModalUserAccount(false);
                            setModifying(false);
                        }}
                        className="px-4"
                    >
                        <i className="fa fa-times me-1"></i>Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            setAddConfirmModalUserAccount(false);
                            handleAddUserAccount();
                        }}
                        className="px-4"
                    >
                        <i className="fa fa-check me-1"></i>Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
            
                    <Modal
                show={showConfirmModalChangeStatus}
                onHide={() => setShowConfirmModalChangeStatus(false)}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-warning">
                        <i className="fa fa-exclamation-circle me-2"></i>Confirm Status Change
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-center fs-5">
                        Are you sure you want to change this user account's status to{" "}
                        <span
                            className={`fw-bold ${
                                confirmAction === "ACTIVE" ? "text-success" : "text-danger"
                            }`}
                        >
                {confirmAction === "ACTIVE" ? "ACTIVE" : "INACTIVE"}
            </span>
                        ?
                    </p>
                    <p className="text-center text-muted">
                        This action will update the user account's current status.
                    </p>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button
                        variant="secondary"
                        onClick={() => setShowConfirmModalChangeStatus(false)}
                        className="px-4"
                    >
                        <i className="fa fa-times me-1"></i>Cancel
                    </Button>
                    <Button
                        variant={confirmAction === "ACTIVE" ? "success" : "danger"}
                        onClick={handleChangeStatus}
                        className="px-4"
                    >
                        <i
                            className={`fa ${
                                confirmAction === "ACTIVE" ? "fa-toggle-on" : "fa-toggle-off"
                            } me-1`}
                        ></i>
                        {confirmAction === "ACTIVE" ? "Activate" : "Deactivate"}
                    </Button>
                </Modal.Footer>
            </Modal>



            <Modal
                size="lg"
                show={showAddModalEventType}
                onHide={() => setAddModalEventType(false)}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-primary">
                        <i className="fa fa-calendar me-2"></i>Add New Event Type
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={confirmAddEventType}>
                        <div className="mb-3">
                            <label htmlFor="event_type" className="form-label fw-bold">
                                Event Type Name <span className="text-danger">*</span>
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                name="event_type"
                                id="event_type"
                                placeholder="Enter event type name"
                                required
                            />
                        </div>
                        <div className="d-flex justify-content-end">
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={isModifying}
                            >
                                {isModifying ? (
                                    <i className="fa fa-spinner fa-spin"></i>
                                ) : (
                                    <i className="fa fa-plus me-1"></i>
                                )}
                                Add
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal
                show={showAddConfrimModalEventType}
                onHide={() => setAddConfirmModalEventType(false)}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-warning">
                        <i className="fa fa-info-circle me-2"></i>Confirm Event Type Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="fs-5">
                        <strong>Event Type Name:</strong> {formData.event_type || "N/A"}
                    </p>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setAddConfirmModalEventType(false);
                            setModifying(false);
                        }}
                        className="px-4"
                    >
                        <i className="fa fa-times me-1"></i>Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            setAddConfirmModalEventType(false);
                            handleAddEventType();
                        }}
                        className="px-4"
                    >
                        <i className="fa fa-check me-1"></i>Confirm
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal
                show={showDeleteModalEventType}
                onHide={() => setShowDeleteModalEventType(false)}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-danger">
                        <i className="fa fa-trash me-2"></i>Delete Event Type
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-center fs-5">
                        Are you sure you want to delete the event type:
                        <span className="fw-bold text-danger"> {deleteEventTypeName || "N/A"}?</span>
                    </p>
                    <p className="text-center text-muted">
                        This action cannot be undone.
                    </p>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button
                        variant="secondary"
                        onClick={() => setShowDeleteModalEventType(false)}
                        className="px-4"
                    >
                        <i className="fa fa-times me-1"></i>Cancel
                    </Button>
                    <Button
                        variant="danger"
                        onClick={() => {
                            handleDeleteEventType(deleteEventTypeId);
                            setShowDeleteModalEventType(false);
                        }}
                        className="px-4"
                    >
                        <i className="fa fa-trash me-1"></i>Delete
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* Add New Position Modal */}
            <Modal
                size="lg"
                show={showAddModalPosition}
                onHide={() => setAddModalPosition(false)}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-primary">
                        <i className="fa fa-briefcase me-2"></i>Add New Position
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={confirmAddPosition}>
                        <div className="mb-3">
                            <label htmlFor="position_name" className="form-label fw-bold">
                                Position Name <span className="text-danger">*</span>
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                name="position_name"
                                id="position_name"
                                placeholder="Enter position name"
                                required
                            />
                        </div>
                        <div className="d-flex justify-content-end">
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={isModifying}
                            >
                                {isModifying ? (
                                    <i className="fa fa-spinner fa-spin"></i>
                                ) : (
                                    <i className="fa fa-plus me-1"></i>
                                )}
                                Add
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            {/* Confirm Position Details Modal */}
            <Modal
                show={showAddConfrimModalPosition}
                onHide={() => setAddConfirmModalPosition(false)}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-warning">
                        <i className="fa fa-info-circle me-2"></i>Confirm Position Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="fs-5">
                        <strong>Position Name:</strong> {formData.position_name || "N/A"}
                    </p>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setAddConfirmModalPosition(false);
                            setModifying(false);
                        }}
                        className="px-4"
                    >
                        <i className="fa fa-times me-1"></i>Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            setAddConfirmModalPosition(false);
                            handleAddPosition();
                        }}
                        className="px-4"
                    >
                        <i className="fa fa-check me-1"></i>Confirm
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal
                show={showDeleteModalPosition}
                onHide={() => setShowDeleteModalPosition(false)}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-danger">
                        <i className="fa fa-trash me-2"></i>Delete Position
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-center fs-5">
                        Are you sure you want to delete the position:
                        <span className="fw-bold text-danger"> {deletePositionName || "N/A"}?</span>
                    </p>
                    <p className="text-center text-muted">
                        This action cannot be undone.
                    </p>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button
                        variant="secondary"
                        onClick={() => setShowDeleteModalPosition(false)}
                        className="px-4"
                    >
                        <i className="fa fa-times me-1"></i>Cancel
                    </Button>
                    <Button
                        variant="danger"
                        onClick={() => {
                            handleDeletePosition(deletePositionId);
                            setShowDeleteModalPosition(false);
                        }}
                        className="px-4"
                    >
                        <i className="fa fa-trash me-1"></i>Delete
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal
                size="lg"
                show={showChangePasswordModal}
                onHide={() => setShowChangePassswordModal(false)}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-primary">
                        <i className="fa fa-key me-2"></i>Change Account Password
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={confirmChangePassword}>
                        <Row className="mb-3">
                            <Col>
                                <label htmlFor="old_password" className="form-label fw-bold">
                                    Old Password <span className="text-danger">*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="password"
                                    name="old_password"
                                    id="old_password"
                                    placeholder="Enter your current password"
                                    required
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <label htmlFor="new_password" className="form-label fw-bold">
                                    New Password <span className="text-danger">*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="password"
                                    name="new_password"
                                    id="new_password"
                                    placeholder="Enter a new password"
                                    required
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <label htmlFor="rep_password" className="form-label fw-bold">
                                    Repeat New Password <span className="text-danger">*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="password"
                                    name="rep_password"
                                    id="rep_password"
                                    placeholder="Repeat the new password"
                                    required
                                />
                            </Col>
                        </Row>
                        <div className="d-flex justify-content-end">
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={isModifying}
                            >
                                {isModifying ? (
                                    <i className="fa fa-spinner fa-spin"></i>
                                ) : (
                                    <i className="fa fa-check me-1"></i>
                                )}
                                Update Password
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal
                show={showConfirmChangePasswordModal}
                onHide={() => setShowConfirmChangePasswordModal(false)}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-warning">
                        <i className="fa fa-exclamation-circle me-2"></i>Confirm Password Change
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-center fs-5">
                        Are you sure you want to change the password?
                    </p>
                    <p className="text-center text-muted">
                        This action will update your account's login credentials.
                    </p>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setShowConfirmChangePasswordModal(false);
                            setModifying(false);
                        }}
                        className="px-4"
                    >
                        <i className="fa fa-times me-1"></i>Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            setShowChangePassswordModal(false);
                            handleChangePassword();
                        }}
                        className="px-4"
                    >
                        <i className="fa fa-check me-1"></i>Confirm
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal
                size="lg"
                show={showAddModalPurok}
                onHide={() => setAddModalPurok(false)}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-primary">
                        <i className="fa fa-plus-circle me-2"></i>Add New Purok
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={confirmAddPurok}>
                        <Row className="mb-3">
                            <Col>
                                <label htmlFor="purok_name" className="form-label fw-bold">
                                    Purok Name <span className="text-danger">*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="purok_name"
                                    id="purok_name"
                                    placeholder="Enter purok name"
                                    required
                                />
                            </Col>
                        </Row>
                        <div className="d-flex justify-content-end">
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={isModifying}
                            >
                                {isModifying ? (
                                    <i className="fa fa-spinner fa-spin"></i>
                                ) : (
                                    <i className="fa fa-check me-1"></i>
                                )}
                                Add
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal
                show={showAddConfrimModalPurok}
                onHide={() => setAddConfirmModalPurok(false)}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-warning">
                        <i className="fa fa-info-circle me-2"></i>Confirm Purok Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="fs-5">
                        <strong>Purok Name:</strong> {formData.purok_name || "N/A"}
                    </p>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setAddConfirmModalPurok(false);
                            setModifying(false);
                        }}
                        className="px-4"
                    >
                        <i className="fa fa-times me-1"></i>Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            setAddConfirmModalPurok(false);
                            handleAddPurok();
                        }}
                        className="px-4"
                    >
                        <i className="fa fa-check me-1"></i>Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showDeleteModalPurok}
                onHide={() => setShowDeleteModalPurok(false)}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-danger">
                        <i className="fa fa-trash me-2"></i>Delete Purok
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-center fs-5">
                        Are you sure you want to delete the purok:{" "}
                        <span className="fw-bold text-danger">{deletePurokName || "N/A"}?</span>
                    </p>
                    <p className="text-center text-muted">
                        This action cannot be undone.
                    </p>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button
                        variant="secondary"
                        onClick={() => setShowDeleteModalPurok(false)}
                        className="px-4"
                    >
                        <i className="fa fa-times me-1"></i>Cancel
                    </Button>
                    <Button
                        variant="danger"
                        onClick={() => {
                            handleDeletePurok(deletePurokId);
                            setShowDeleteModalPurok(false);
                        }}
                        className="px-4"
                    >
                        <i className="fa fa-trash me-1"></i>Delete
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default Settings