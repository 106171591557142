const Footer = () => {
 return (
     <>
         <footer className="bg-white sticky-footer">
             <div className="container my-auto">
                 <div className="text-center my-auto copyright">
                     <span>Copyright&nbsp;© {new Date().getFullYear()} Barangay System. All Rights Reserved.</span>
                 </div>
             </div>
         </footer>
     </>
 )
}

export default Footer;