import Cookies from "js-cookie";
import {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import Loading from "../others/Loading";
import ReactPaginate from "react-paginate";
import {useNavigate} from "react-router-dom";

const Household = () => {
    const token = Cookies.get('token');
    const navigate = useNavigate();

    const [pageNumberHousehold, setPageNumberHousehold] = useState(0);
    const [pageSizeHousehold, setPageSizeHousehold] = useState(10);
    const [totalHousehold, setTotalHousehold] = useState(0);
    const [searchTermHousehold, setSearchTermHousehold] = useState('');
    const [isLoadingHousehold, setLoadingHousehold] = useState(true)
    const [household, setHousehold] = useState([])
    const pageCountHousehold = Math.ceil(totalHousehold / pageSizeHousehold);

    const handlePageChangeHousehold = ({selected}) => {
        setPageNumberHousehold(selected);
    };

    useEffect(() => {
        fetchHousehold();
    }, [token, pageNumberHousehold, pageSizeHousehold, searchTermHousehold]);

    useEffect(() => {
        setPageNumberHousehold(0); // Reset the page number when search term changes
    }, [searchTermHousehold]);

    const fetchHousehold = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/households?page=${pageNumberHousehold + 1}&page_size=${pageSizeHousehold}&search=${searchTermHousehold}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setHousehold(response.data.data);
                setTotalHousehold(response.data.total); // Ensure total is set correctly
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setLoadingHousehold(false)
            })
    };

    function handleMembers(id, person_name, purok_name) {
        const encodedEventName = encodeURIComponent(person_name);
        const encodedPurokName = encodeURIComponent(purok_name);
        navigate(`/portal/household/members/${id}/${encodedEventName}/${encodedPurokName}`);
    }

    if (isLoadingHousehold) {
        return (<Loading/>);
    }


    return (
        <>
            <div className="container-fluid">
                <h3 className="text-center text-white bg-dark py-3 rounded mt-3">
                    <i className="fa fa-home me-2"></i>Household Information
                </h3>
                <div className="card shadow border-0">
                    <div className="card-header d-flex justify-content-between align-items-center bg-light">
                        <h5 className="text-primary fw-bold mb-0">
                            <i className="fa fa-list me-2"></i>Household Information
                        </h5>
                    </div>
                    <div className="card-body">
                        {/* Search and Pagination */}
                        <div className="row mb-4">
                            <div className="col-md-9">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search Household / Purok"
                                    value={searchTermHousehold}
                                    onChange={(e) => setSearchTermHousehold(e.target.value)}
                                />
                            </div>
                            <div className="col-md-3">
                                <select
                                    className="form-select"
                                    value={pageSizeHousehold}
                                    onChange={(e) => {
                                        setPageSizeHousehold(Number(e.target.value));
                                        setPageNumberHousehold(0);
                                    }}
                                >
                                    {[10, 20, 30, 40, 50].map((size) => (
                                        <option key={size} value={size}>
                                            Show {size} per page
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        {/* Table */}
                        <div className="table-responsive">
                            <table className="table table-bordered table-hover align-middle">
                                <thead className="table-primary">
                                <tr>
                                    <th>#</th>
                                    <th>Last Name</th>
                                    <th>Middle Name</th>
                                    <th>First Name</th>
                                    <th>Suffix</th>
                                    <th>Purok</th>
                                    <th>Total Beneficiaries</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {household.length === 0 ? (
                                    <tr>
                                        <td colSpan="15" className="text-center text-muted">
                                            No results found.
                                        </td>
                                    </tr>
                                ) : (
                                    household.map((data, index) => (
                                        <tr key={data.id}>
                                            <td>{index + 1 + pageNumberHousehold * pageSizeHousehold}</td>
                                            <td>{data.person.lastname}</td>
                                            <td>{data.person.middlename}</td>
                                            <td>{data.person.firstname}</td>
                                            <td>{data.person.suffix}</td>
                                            <td>{data.person.purok?.purok_name || "N/A"}</td>
                                            <td>{data.total_beneficiaries || "N/A"}</td>
                                            <td>
                                                <button
                                                    className="btn btn-info btn-sm"
                                                    onClick={() =>
                                                        handleMembers(data.id, `${data.person.lastname} ${data.person.middlename} ${data.person.firstname} ${data.person.suffix}`, data.person.purok?.purok_name)
                                                    }
                                                >
                                                    <i className="fa fa-users me-1"></i>Household Members
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                                </tbody>
                            </table>
                        </div>

                        {/* Pagination */}
                        <ReactPaginate
                            pageCount={pageCountHousehold}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChangeHousehold}
                            containerClassName="pagination justify-content-center mt-4"
                            activeClassName="active"
                            pageLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            breakLinkClassName="page-link"
                            pageClassName="page-item"
                            previousClassName="page-item"
                            nextClassName="page-item"
                            breakClassName="page-item"
                            disabledClassName="disabled"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Household